import { CardRoutes } from '../types';
import { getPathname } from './navigation';

export const urlFromItem = ({
  __typename,
  slug
}: {
  __typename: string;
  slug: string;
}): string => {
  switch (__typename) {
    case 'Movie':
      return getPathname(CardRoutes.MovieCard, { slug });
    case 'Series':
      return getPathname(CardRoutes.SeriesCard, { slug });
    case 'Bundle':
    case 'MetaCollectionBundle':
      return getPathname(CardRoutes.Collection, { slug });
    default:
      console.error('Unknown type', __typename);
      return '';
  }
};
