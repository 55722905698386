import { useL10n } from '@sfstudios/shapeshifter';
import { useEffect } from 'react';

export const useHeadTitle = () => {
  const { t } = useL10n();
  const brandName = t('glossary.brand');
  useEffect(() => {
    if (brandName === 'glossary.brand') return;
    const link = document.createElement('title');
    link.innerHTML = t('glossary.brand');
    document.getElementsByTagName('head')[0].appendChild(link);
  }, [brandName, t]);
};
