import { darkTheme } from '@sfstudios/shapeshifter';
import React, { ReactNode, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { useStartedFromDeepLink } from '../context/DeepLinkContext';
import { useKeyListener } from '../hooks/useKeyListener';
import { OutOfBoundsProvider } from '../hooks/useOutOfBounds';
import { useReady } from '../hooks/useReady';
import { useNewFocusEngine } from '../newFocusEngineRoutes';
import { whiteSpace } from '../theme';
import { Platform } from '../utils/platform';
import { vh100 } from '../utils/styleUtils';
import { Focusable } from './Focusable';
import { Menu, MENU_WIDTH_PX } from './Menu';
import { Menu2 } from './Menu/Menu2';

const ContentWrapper = styled.div<{ withPadding: boolean }>`
  display: flex;
  padding: 0 ${(props) => (props.withPadding ? whiteSpace['12'] : '')};
  flex: 1;
  min-height: ${vh100};
  flex-direction: column;
  overflow: hidden;
  background: ${darkTheme.color.ui800};
`;

const MenuWrapper = styled.div`
  display: block;
`;

const FlexWrapper = styled.div<{ card: boolean }>`
  display: flex;
  margin-left: ${MENU_WIDTH_PX}px;
  width: calc(100% - ${MENU_WIDTH_PX}px);
  ${(props) =>
    props.card
      ? css`
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 10;
        `
      : ''}
`;

const resetScrollHandler = (event: React.UIEvent<HTMLDivElement>) =>
  (event.currentTarget.scrollTop = 0);

const MenuBackHandler = React.memo(
  ({ focusMenu }: { focusMenu: () => void }) => {
    useKeyListener({
      back: (_, numListeners) => {
        /**
         * Focus the menu if there's only 2 key listeners left for "back".
         * The two left will be this one, and the one in <AppExiter />,
         * which controls the exit prompt.
         */
        if (numListeners === 2) {
          focusMenu();
        }
      }
    });

    return null;
  }
);

const InnerLayout = ({
  children,
  card = false,
  withPadding = !card,
  menuVersion
}: {
  children: ReactNode;
  card?: boolean;
  withPadding?: boolean;
  menuVersion: 1 | 2;
}) => {
  const [focusedCol, setFocusedCol] = useState<'MENU' | 'CONTENT'>('CONTENT');
  const ready = useReady();

  const focusMenu = useCallback(() => {
    setFocusedCol('MENU');
  }, [setFocusedCol]);

  const startedFromDeepLink = useStartedFromDeepLink();
  const backButtonGoesToMenuFirst = Platform.samsung()
    ? !startedFromDeepLink
    : true;

  const onClose = useCallback(() => setFocusedCol('CONTENT'), []);

  return (
    <FlexWrapper card={card}>
      {menuVersion === 1 && (
        <Focusable focused={focusedCol === 'MENU'} as={MenuWrapper}>
          <Menu close={onClose} />
        </Focusable>
      )}
      {menuVersion === 2 && <Menu2 />}

      {focusedCol === 'CONTENT' && backButtonGoesToMenuFirst ? (
        <MenuBackHandler focusMenu={focusMenu} />
      ) : null}
      <Focusable
        as={ContentWrapper}
        onScroll={resetScrollHandler}
        focused={focusedCol === 'CONTENT'}
        withPadding={withPadding}
      >
        <OutOfBoundsProvider left={() => setFocusedCol('MENU')}>
          {ready ? children : null}
        </OutOfBoundsProvider>
      </Focusable>
    </FlexWrapper>
  );
};

export const Layout = ({
  children,
  card = false,
  withPadding = !card
}: {
  children: ReactNode;
  card?: boolean;
  withPadding?: boolean;
}) => {
  const location = useLocation();
  const menuVersion = useNewFocusEngine() ? 2 : 1;

  return (
    <InnerLayout
      card={card}
      withPadding={withPadding}
      key={location.pathname}
      menuVersion={menuVersion}
    >
      {children}
    </InnerLayout>
  );
};
