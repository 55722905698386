export const getSamsungVersion = (ua?: string): number | null => {
  ua = ua ?? navigator.userAgent;

  if (ua.includes('Tizen')) {
    // Lets try to get the version number from the user agent string starting with Tizen
    // This will make us at least get a number instead of null

    const tizenVersion = ua.match(/Tizen ([0-9\\.]+)/i);

    if (tizenVersion?.[1]) {
      return parseFloat(tizenVersion[1]);
    }
  }

  return null;
};
