// From CHAT-GPT
export function distanceToLineSegment({
  point,
  lineSegmentStart,
  lineSegmentEnd
}: {
  point: { x: number; y: number };
  lineSegmentStart: { x: number; y: number };
  lineSegmentEnd: { x: number; y: number };
}): number {
  const { x: px, y: py } = point;
  const { x: x1, y: y1 } = lineSegmentStart;
  const { x: x2, y: y2 } = lineSegmentEnd;

  // Function to calculate the squared distance between two points
  function squaredDistance(
    x1: number,
    y1: number,
    x2: number,
    y2: number
  ): number {
    const dx = x2 - x1;
    const dy = y2 - y1;
    return dx * dx + dy * dy;
  }

  // Calculate the squared length of the line segment
  const squaredLineLength = squaredDistance(x1, y1, x2, y2);

  // If the line segment has zero length, return the distance to one of its endpoints
  if (squaredLineLength === 0) {
    return Math.sqrt(squaredDistance(px, py, x1, y1));
  }

  // Calculate the parameter (t) for the point on the line segment closest to the point
  const t = Math.max(
    0,
    Math.min(
      1,
      ((px - x1) * (x2 - x1) + (py - y1) * (y2 - y1)) / squaredLineLength
    )
  );

  // Calculate the coordinates of the closest point on the line segment
  const closestX = x1 + t * (x2 - x1);
  const closestY = y1 + t * (y2 - y1);

  // Calculate and return the distance between the point and the closest point on the line segment
  return Math.sqrt(squaredDistance(px, py, closestX, closestY));
}
