import { debugColors } from './stories/utils/components';
import { Candidate, El, ElWithRect, Shape } from './types';

export function allFocusableChildren(
  el: Omit<El, 'groupId' | 'parentGroupId'>
) {
  const focusableElements: El[] = [];
  const { routeKey } = el;
  for (let i = 0; i < el.node.childNodes.length; i++) {
    const node = el.node.childNodes[i];
    const { isFocusGroup, groupId, layer: currentLayer } = getData(node);
    const parentGroupId = el.groups[0];
    const layer = currentLayer ?? el.layer;

    // recursively get all focusable children of node
    focusableElements.push(
      ...allFocusableChildren({
        node,
        groups: groupId ? [groupId, ...el.groups] : el.groups,
        isFocusGroup,
        routeKey,
        layer
      })
    );

    // save current node if focusable
    if (node.nodeName === 'BUTTON' || node.nodeName === 'INPUT') {
      focusableElements.push({
        node,
        parentGroupId,
        groups: el.groups,
        isFocusGroup: false,
        routeKey,
        layer
      });
    }
    if (isFocusGroup) {
      focusableElements.push({
        node,
        groupId,
        isFocusGroup: true,
        parentGroupId,
        groups: el.groups,
        routeKey,
        layer
      });
    }
  }
  return focusableElements;
}

export function getData(node: ChildNode) {
  const dataset = (node as any).dataset;
  const groupId = dataset?.focusGroup;
  const blockMovingUp = dataset?.focusBlockUp === 'true';
  const blockMovingDown = dataset?.focusBlockDown === 'true';
  const blockMovingLeft = dataset?.focusBlockLeft === 'true';
  const blockMovingRight = dataset?.focusBlockRight === 'true';
  const verticalAutoScrollPosition = dataset?.verticalAutoScrollPosition;
  const horizontalAutoScrollPosition = dataset?.horizontalAutoScrollPosition;
  const autoFocusOnMount = dataset?.autoFocusOnMount !== 'false';
  const preferFocus = dataset?.preferFocus === 'true';
  const layer = dataset?.layer;
  return {
    groupId,
    blockMovingUp,
    blockMovingDown,
    blockMovingLeft,
    blockMovingRight,
    verticalAutoScrollPosition,
    horizontalAutoScrollPosition,
    autoFocusOnMount,
    isFocusGroup: !!groupId,
    preferFocus,
    layer
  };
}

export const addDebuggingStyles = ({
  focusedElement,
  focusableElements,
  candidates,
  closestElement,
  previousFocusedElement
}: {
  focusedElement: ElWithRect | undefined;
  focusableElements: ElWithRect[];
  candidates: Candidate[];
  closestElement: ElWithRect | undefined;
  previousFocusedElement: ElWithRect | undefined;
}) => {
  focusableElements.forEach((c) => {
    const node = c.element.node as any;
    if (!node) return;
    node.style.outline = '';
  });
  candidates.forEach((c) => {
    const node = c.element.node as any;
    if (!node) return;
    const color =
      c.distance === c.weightedDistance
        ? debugColors.candidate.default
        : debugColors.candidate.hasWeightedDistance;
    node.style.outline = `4px solid ${color}`;
  });

  if (previousFocusedElement) {
    const node = previousFocusedElement.element.node as any;
    node.style.outline = `4px solid ${debugColors.prevFocus}`;
  }

  if (focusedElement) {
    const node = focusedElement.element.node as any;
    node.style.outline = `6px solid ${debugColors.focus}`;
  }

  console.log({
    focusedElement,
    candidates,
    closestElement,
    previousFocusedElement,
    focusableElements
  });
};

/**
 * @returns position of the caret if the element has one, input elements etc
 * */
export const getCaret = ({
  element,
  averageInputCharacterWidth
}: {
  element: ElWithRect | undefined;
  averageInputCharacterWidth: number;
}): Shape | undefined => {
  if (!element) return undefined;

  const charactersTyped = (element?.element?.node as HTMLInputElement)
    .selectionStart;

  if (charactersTyped == null) return undefined;

  const x =
    element.rect.leftCenter.x + charactersTyped * averageInputCharacterWidth;
  const y = element.rect.center.y;

  return {
    center: { x, y },
    topCenter: { x, y },
    bottomCenter: { x, y },
    leftCenter: { x, y },
    rightCenter: { x, y },
    bottomLeft: { x, y },
    bottomRight: { x, y },
    topLeft: { x, y },
    topRight: { x, y }
  };
};
