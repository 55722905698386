import { useCallback } from 'react';
import { useIsCursorVisible } from '../context/MouseContext';
export const useRemoteControllerFocus = () => {
  const isCursorVisible = useIsCursorVisible();
  return useCallback(
    (rowOrColNumber: number, currentColumnOrRow: number) =>
      rowOrColNumber === currentColumnOrRow && !isCursorVisible,
    [isCursorVisible]
  );
};
