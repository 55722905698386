import {
  AppEnvironment,
  ServiceAvailabilityType,
  useServiceAvailability
} from '@sfstudios/shapeshifter';
import { useOnBecomeVisible } from '../../../hooks/useOnBecomeVisible';

export const useServiceAvailabilityModal = () => {
  const {
    messagesToShow,
    checkForNewMessages,
    dismiss
  } = useServiceAvailability({
    type: ServiceAvailabilityType.General,
    environment:
      process.env.NODE_ENV === 'development'
        ? AppEnvironment.staging
        : AppEnvironment.production
  });

  useOnBecomeVisible(checkForNewMessages);

  const messageToShow = messagesToShow[0];

  return { messageToShow, dismiss };
};
