type EnvVariableKey =
  | 'REACT_APP_CDN_PATH'
  | 'REACT_APP_GRAPHQL_HOST'
  | 'REACT_APP_GRAPHQL_WS_HOST'
  | 'REACT_APP_BRAND'
  | 'REACT_APP_PLATFORM'
  | 'REACT_APP_HEROKU_APP_ID'
  | 'REACT_APP_HEROKU_APP_NAME'
  | 'REACT_APP_HEROKU_DYNO_ID'
  | 'REACT_APP_HEROKU_RELEASE_CREATED_AT'
  | 'REACT_APP_HEROKU_RELEASE_VERSION'
  | 'REACT_APP_HEROKU_SLUG_COMMIT'
  | 'REACT_APP_HEROKU_SLUG_DESCRIPTION'
  | 'REACT_APP_ENV_NAME'
  | 'REACT_APP_ID'
  | 'REACT_APP_FORCE_SHAKA_PLAYER_ON_SAMSUNG';

declare global {
  interface Window {
    __ENV__?: Record<string, string> | undefined;
  }
}

export const getEnvVariable = (
  key: EnvVariableKey,
  defaultValue: string
): string => {
  let env = window?.__ENV__;
  if (process.env.NODE_ENV === 'development' && process.env) {
    env = process.env as any;
  }
  return env?.[key] ?? defaultValue;
};
