import { Routes } from './types';
import { preventScroll } from './useKeyListeners';

type Params = {
  routes: Routes;
  id: string;
  routeKey: string;
};

export const focusElementInGroupIfNeeded = (params: Params) => {
  const { routes, routeKey } = params;
  const response = shouldSetFocusToElement(params);

  if (response.focus) {
    const focusGroupToFocus = routes[routeKey].focusGroups[response.focusGroup];
    const toFocus = focusGroupToFocus.focusableChildren[response.index];

    if (!toFocus?.node) {
      console.warn('can find element to focus');
      return;
    }

    if (!focusGroupToFocus.autoFocusOnMount) return;

    (toFocus.node as any).focus({ preventScroll });
  }
};

const shouldSetFocusToElement = ({
  routes,
  routeKey,
  id
}: Params): { reason: string } & (
  | {
      focus: false;
    }
  | {
      focus: true;
      index: number;
      focusGroup: string;
    }
) => {
  const { focusableChildren } = routes[routeKey].focusGroups[id];
  const currentRoute = routes[routeKey];

  if (focusableChildren.length === 0) {
    return { focus: false, reason: 'no focusable children' };
  }

  if (routes[routeKey].hasReceivedFocusFromHistory) {
    return {
      focus: false,
      reason: 'went back to a page which previously had focus on another group'
    };
  }

  // Prev focused when page was visited before
  if (currentRoute.lastFocusBeforeLeaveRoute?.group === id) {
    routes[routeKey].hasReceivedFocusFromHistory = true;
    return {
      focus: true,
      focusGroup: id,
      index: currentRoute.lastFocusBeforeLeaveRoute.index,
      reason: 'went back to a page which previously had focus on this group'
    };
  }

  // Preferred focus
  if (currentRoute.preferredFocusGroup) {
    if (currentRoute.preferredFocusGroup === id)
      return {
        focus: true,
        focusGroup: id,
        index: 0,
        reason: 'Preferred focus on this element'
      };
    else {
      return {
        focus: false,
        reason: 'Preferred focus on another element in this group'
      };
    }
  }

  return {
    focus: true,
    focusGroup: id,
    index: 0,
    reason: 'No reason not to focus element. Focusing first element in group.'
  };
};
