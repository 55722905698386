import { exists } from '@sfstudios/shapeshifter';
import { Platform } from '../../utils/platform';

/**
 * Philips RemoteControl
 * https://partner.zeasn.com/partnerportal/RemoteControl/index.jhtml
 */
declare global {
  const VK_LEFT: number;
  const VK_RIGHT: number;
  const VK_UP: number;
  const VK_DOWN: number;
  const VK_ENTER: number;
  const VK_BACK: number;
  const VK_0: number;
  const VK_1: number;
  const VK_2: number;
  const VK_3: number;
  const VK_4: number;
  const VK_5: number;
  const VK_6: number;
  const VK_7: number;
  const VK_8: number;
  const VK_9: number;
  const VK_RED: number;
  const VK_GREEN: number;
  const VK_YELLOW: number;
  const VK_BLUE: number;
  const VK_PLAY: number;
  const VK_PAUSE: number;
  const VK_STOP: number;
  const VK_FAST_FWD: number;
  const VK_REWIND: number;
  const VK_PAGE_UP: number;
  const VK_PAGE_DOWN: number;
  const VK_INF: number;
  const VK_INFO: number;
  const VK_LGE_MAGIC_VOICE: number;
}

type RemoteKey =
  | 'LEFT'
  | 'RIGHT'
  | 'UP'
  | 'DOWN'
  | 'ENTER'
  | 'RETURN'
  | 'ZERO'
  | 'ONE'
  | 'TWO'
  | 'THREE'
  | 'FOUR'
  | 'FIVE'
  | 'SIX'
  | 'SEVEN'
  | 'EIGHT'
  | 'NINE'
  | 'RED'
  | 'GREEN'
  | 'YELLOW'
  | 'BLUE'
  | 'PLAY'
  | 'PAUSE'
  | 'STOP'
  | 'FF'
  | 'RW'
  | 'PUP'
  | 'PDOWN'
  | 'INFO'
  | 'MA'
  | 'MAGIC_VOICE';

type PlatformKeyMap = Partial<Record<RemoteKey, number>>;

export const getOsMaps = () => {
  let PHILIPS = {};

  try {
    PHILIPS = {
      LEFT: VK_LEFT,
      RIGHT: VK_RIGHT,
      UP: VK_UP,
      DOWN: VK_DOWN,
      ENTER: VK_ENTER,
      RETURN: VK_BACK,
      ZERO: VK_0,
      ONE: VK_1,
      TWO: VK_2,
      THREE: VK_3,
      FOUR: VK_4,
      FIVE: VK_5,
      SIX: VK_6,
      SEVEN: VK_7,
      EIGHT: VK_8,
      NINE: VK_9,
      RED: VK_RED,
      GREEN: VK_GREEN,
      YELLOW: VK_YELLOW,
      BLUE: VK_BLUE,
      PLAY: VK_PLAY,
      PAUSE: VK_PAUSE,
      STOP: VK_STOP,
      FF: VK_FAST_FWD,
      RW: VK_REWIND,
      PUP: VK_PAGE_UP,
      PDOWN: VK_PAGE_DOWN,
      INFO: VK_INFO
    };
  } catch (error) {
    if (Platform.philips()) {
      console.warn('Failed to create keymap from constants.');
    }
  }

  const LG: PlatformKeyMap = {
    LEFT: 37,
    RIGHT: 39,
    UP: 38,
    DOWN: 40,
    ENTER: 13,
    RETURN: 461,
    ZERO: 48,
    ONE: 49,
    TWO: 50,
    THREE: 51,
    FOUR: 52,
    FIVE: 53,
    SIX: 54,
    SEVEN: 55,
    EIGHT: 56,
    NINE: 57,
    RED: 403,
    GREEN: 404,
    YELLOW: 405,
    BLUE: 406,
    PLAY: 415,
    PAUSE: 19,
    STOP: 413,
    FF: 417,
    RW: 412,
    PUP: 33,
    PDOWN: 34,
    INFO: 457,
    MAGIC_VOICE: 1015
  };

  // https://developer.samsung.com/smarttv/develop/guides/user-interaction/remote-control.html
  const SAMSUNG: PlatformKeyMap = {
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    ENTER: 13,
    RETURN: 10009,
    ZERO: 48,
    ONE: 49,
    TWO: 50,
    THREE: 51,
    FOUR: 52,
    FIVE: 53,
    SIX: 54,
    SEVEN: 55,
    EIGHT: 56,
    NINE: 57,
    PUP: 10252,
    RW: 412,
    FF: 417,
    PLAY: 415,
    PAUSE: 19,
    STOP: 413,
    RED: 403,
    GREEN: 404,
    YELLOW: 405,
    BLUE: 406,
    INFO: 457
  };

  const VEWD: PlatformKeyMap = { RETURN: 8 };

  return { PHILIPS: PHILIPS as PlatformKeyMap, LG, VEWD, SAMSUNG };
};

export const getKeyMap = (): Partial<
  Record<
    | 'MediaStop'
    | 'Back'
    | 'MediaPause'
    | 'MediaForward'
    | 'MediaPlay'
    | 'MediaPause'
    | 'MediaRewind'
    | 'Enter'
    | 'Up'
    | 'Down',
    number[]
  >
> | null => {
  const { LG, VEWD, PHILIPS, SAMSUNG } = getOsMaps();

  switch (Platform.OS) {
    case 'lg': {
      return {
        // TODO: Why is not stop enabled?
        Back: [LG.RETURN].filter(exists),
        MediaStop: [LG.STOP].filter(exists),
        MediaPause: [LG.PAUSE].filter(exists),
        MediaPlay: [LG.PLAY].filter(exists),
        MediaRewind: [LG.RW].filter(exists),
        MediaForward: [LG.FF].filter(exists),
        Enter: [LG.ENTER].filter(exists),
        Up: [LG.UP].filter(exists),
        Down: [LG.DOWN].filter(exists)
      };
    }

    /**
     * Vewd is mostly the same as LG, with a few exceptions like additional
     * keycodes for Back.
     */
    case 'vewd': {
      return {
        Back: [LG.RETURN, VEWD.RETURN].filter(exists),
        MediaPause: [LG.PAUSE].filter(exists),
        MediaPlay: [LG.PLAY].filter(exists),
        MediaRewind: [LG.RW].filter(exists),
        MediaForward: [LG.FF].filter(exists),
        Enter: [LG.ENTER].filter(exists),
        Up: [LG.UP].filter(exists)
      };
    }

    case 'philips': {
      // https://partner.zeasn.com/partnerportal/RemoteControl/index.jhtml
      return {
        Back: [LG.RETURN, PHILIPS.RETURN].filter(exists),
        MediaStop: [PHILIPS.STOP].filter(exists),
        MediaPause: [LG.PAUSE, PHILIPS.PAUSE].filter(exists),
        MediaPlay: [LG.PLAY, PHILIPS.PLAY].filter(exists),
        MediaRewind: [LG.RW, PHILIPS.RW].filter(exists),
        MediaForward: [LG.FF, PHILIPS.FF].filter(exists),
        Enter: [LG.ENTER, PHILIPS.ENTER].filter(exists),
        Up: [LG.UP, PHILIPS.UP].filter(exists),
        Down: [LG.DOWN, PHILIPS.DOWN].filter(exists)
      };
    }

    case 'samsung': {
      return {
        Back: [SAMSUNG.RETURN].filter(exists),
        MediaStop: [SAMSUNG.STOP].filter(exists),
        MediaPause: [SAMSUNG.PAUSE].filter(exists),
        MediaPlay: [SAMSUNG.PLAY].filter(exists),
        MediaRewind: [SAMSUNG.RW].filter(exists),
        MediaForward: [SAMSUNG.FF].filter(exists),
        Enter: [SAMSUNG.ENTER].filter(exists),
        Up: [SAMSUNG.UP].filter(exists),
        Down: [SAMSUNG.DOWN].filter(exists)
      };
    }

    default: {
      return null;
    }
  }
};
