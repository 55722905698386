import { ApolloClient, GQL_SEND_LOGS } from '@sfstudios/shapeshifter';

export const apolloLog = (client: ApolloClient, logs: string[]) =>
  client.mutate({
    mutation: GQL_SEND_LOGS,
    variables: {
      logs
    },
    context: { isLog: true },
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache'
  });
