import { darkTheme } from '@sfstudios/shapeshifter';
import styled from 'styled-components/macro';
import { fontSize, whiteSpace } from '../../../theme';
import { breakpoints } from '../../../utils/breakpoints';
import Icon from '../../Icon';

export const SettingsStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: ${fontSize['2xl']};
    ${breakpoints.tvLowRes`
    font-size: 40px;
`};
    letter-spacing: 1.5px;
    font-weight: bold;
  }
`;

export const DiscountOriginLabelWrapper = styled.span`
  display: inline-block;
  background-color: ${darkTheme.color.ui300};
  border-radius: 100em;
  color: ${darkTheme.color.white};
  font-weight: bold;
  font-size: ${fontSize.sm};
  text-transform: uppercase;
  transform: translateY(-0.2em);
  margin-top: 3px;
`;

export const CampaignDiscountOriginLabel = styled.span`
  display: inline-block;
  background-color: ${darkTheme.color.error};
  color: ${darkTheme.color.white};
  font-weight: bold;
  padding: 0.3em 0.5em;
  border-radius: 100em;
`;

export const VoucherDiscountOriginLabel = styled.span`
  display: inline-block;
  padding: 0.3em 0.5em;
`;

export const SettingsSummary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 400px;
`;

export const LanguageTracksTable = styled.table`
  margin: ${whiteSpace[2]} 0 ${whiteSpace[8]};
`;

export const LanguageTrack = styled.tr`
  padding: 0.25rem 0;
`;

export const LanguageTracksHeading = styled.th`
  color: ${darkTheme.color.ui300};
  font-size: ${fontSize.lg};
  font-weight: 400;
  text-align: left;
  padding-right: 1rem;
  vertical-align: baseline;
`;

export const LanguageTracksItems = styled.td`
  color: ${darkTheme.color.white};
  font-size: ${fontSize.lg};
  font-weight: 400;
`;

export const PurchaseTypeRow = styled.tr`
  font-size: ${fontSize.lg};
  margin: ${whiteSpace[1]} 0;
  padding: 0.25rem 0;
`;

export const PurchaseTypeHeading = styled.th`
  text-align: left;
  padding-right: 0.5rem;
  vertical-align: baseline;
`;

export const PurchaseTypeData = styled.td``;

export const CheckoutModalText = styled.div`
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 1px;
  text-align: center;
  color: ${darkTheme.color.ui300};
  max-width: 440px;
  min-height: 6rem;
`;

export const PaymentOptionsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`;

export const DirectPaymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DirectPaymentButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

export const DirectPayOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }

  ${CheckoutModalText} {
    margin-top: 8px;
  }
`;

export const DividerContainer = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DividerLine = styled.div`
  flex: 1;
  width: 2px;
  background-color: rgba(255, 255, 255, 0.1);
`;

export const DividerText = styled.div`
  padding: 16px;
  font-size: 24px;
  color: ${darkTheme.color.ui300};

  z-index: 1;
`;

export const SavedPaymentMethod = styled.div`
  color: ${darkTheme.color.ui300};

  font-size: 26px;
  font-weight: 400;
`;

export const CardInfo = styled.span`
  display: inline-flex;
  flex-direction: row;
  align-items: baseline;
  font-family: Roboto Mono;
  margin: 0 1rem;
`;

export const CardBrandSymbol = styled.div<{ image: string }>`
  width: 2rem;
  display: inline-block;
  height: 2rem;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 1rem;
  transform: translateY(0.5rem);
`;

export const CardSwitchText = styled.div<{ wide: boolean }>`
  line-height: ${(props) => (props.wide ? '3rem' : '1.5rem')};
  max-width: ${(props) => (props.wide ? '100%' : '424px')};
  margin: ${(props) => (props.wide ? '0 0 0 1rem' : '0px -42px')};
`;

export const CardSwitchIcon = styled(Icon)<{ focused: boolean }>`
  width: 2rem;
  height: 2rem;

  fill: ${(props) =>
    props.focused ? darkTheme.color.ui900 : darkTheme.color.ui300};
`;

export const CardSwitchBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const CheckoutModalContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  height: 80%;
  background-color: ${darkTheme.color.ui900};
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: ${whiteSpace[20]};
  background-color: ${darkTheme.color.ui800};
`;

export const BodySection = styled.div`
  align-self: center;
  overflow-y: hidden;
  /* prettier-ignore */
  padding: ${whiteSpace['12']} ${whiteSpace['24']} ${whiteSpace[
    '6'
  ]} ${whiteSpace['24']};
  width: 100%;
  height: 100%;
`;
