import {
  BrandOverrideStorage,
  brandOverrideStorageKey,
  isBrand,
  setDarkThemeBrand
} from '@sfstudios/shapeshifter';
import { brand } from './brand';

function getBrandOverrideFromStorage(): BrandOverrideStorage | null {
  const storedString = localStorage.getItem(brandOverrideStorageKey);
  if (!storedString) return null;
  const { brand } = JSON.parse(storedString) as BrandOverrideStorage;
  if (!isBrand(brand)) return null;
  return { brand };
}

// Can be removed when stopped using direct imports of "darkTheme"
(function setInitialDarkThemeBrand() {
  const brandOverride = getBrandOverrideFromStorage();
  const brandToUse = brandOverride ? brandOverride.brand : brand;
  setDarkThemeBrand(brandToUse);
})();
