export enum EVENTS {
  TAKEOVER_INFO_CLICK = 'TAKEOVER_INFO_CLICK',
  CLICK_NEXT_EPISODE_BUTTON = 'CLICK_NEXT_EPISODE_BUTTON',
  CLICK_SKIP_INTRO_BUTTON = 'CLICK_SKIP_INTRO_BUTTON',
  SHOW_NEXT_EPISODE_BUTTON = 'SHOW_NEXT_EPISODE_BUTTON',
  SHOW_SKIP_INTRO_BUTTON = 'SHOW_SKIP_INTRO_BUTTON',
  CHECKOUT_STARTED = 'CHECKOUT_STARTED',
  CHECKOUT_REMOTE_CONNECTED = 'CHECKOUT_REMOTE_CONNECTED',
  CHECKOUT_STEP_REMOTE_CHECKOUT = 'CHECKOUT_STEP_REMOTE_CHECKOUT',
  CHECKOUT_STEP_CONFIRM_USE_SAVED_PURCHASE_METHOD = 'CHECKOUT_STEP_CONFIRM_USE_SAVED_PURCHASE_METHOD',
  CHECKOUT_COMPLETED = 'CHECKOUT_COMPLETED',
  BUY_BUTTON_CLICK = 'BUY_BUTTON_CLICK',
  LOG_IN_STARTED = 'LOG_IN_STARTED',
  LOG_IN_REMOTE_CONNECTED = 'LOG_IN_REMOTE_CONNECTED',
  NAVIGATION_MENU_CLICK = 'NAVIGATION_MENU_CLICK',
  COVER_CLICK_EVENT = 'COVER_CLICK_EVENT',
  QR_CODE_URL_ERROR = 'QR_CODE_URL_ERROR',
  QR_CODE_URL_SUCCESS = 'QR_CODE_URL_SUCCESS'
}
