import styled from 'styled-components';
import { useFocusEngine } from '../../useFocusEngine';

export const Button = styled.button`
  margin: 5px;
  width: 6rem;
  height: 6rem;
  background-color: #ddd;
  border: 0px solid red;
  flex-shrink: 0;
  overflow-wrap: anywhere;

  box-sizing: border-box;
  :focus {
    border: 4px solid red;
  }
`;

export const Input = styled.input`
  height: 2rem;

  :focus {
    outline: 4px solid red;
  }
`;

export const debugColors = {
  candidate: {
    default: 'orange',
    hasWeightedDistance: 'lightgreen'
  },
  focus: 'purple',
  prevFocus: 'pink'
};

export const DebugInfo = () => {
  const { debug } = useFocusEngine();

  if (!debug) return null;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        paddingLeft: 10
      }}
    >
      <div style={{ display: 'flex' }}>
        <Square color={debugColors.candidate.default} />= focus = focus
        candidate
      </div>
      <div style={{ display: 'flex' }}>
        <Square color={debugColors.candidate.hasWeightedDistance} />= focus
        candidate with artificially shortened distance (focus score)
      </div>
      <div style={{ display: 'flex' }}>
        <Square color={debugColors.prevFocus} />= previously focused
      </div>
      <div style={{ display: 'flex' }}>
        <Square color={debugColors.focus} />= current focus
      </div>
    </div>
  );
};

const Square = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border: 5px solid ${({ color }) => color};
`;
