import React from 'react';
import styled from 'styled-components/macro';
import { useKeyListener } from '../hooks/useKeyListener';
import { useOutOfBoundsRef } from '../hooks/useOutOfBounds';
import { MENU_WIDTH_PX } from './Menu';

const FixedStyles = styled.div<{ backgroundColor?: string; showMenu: boolean }>`
  position: fixed;
  top: 0;
  left: ${(props) => (props.showMenu ? `${MENU_WIDTH_PX}px` : 0)};
  background: ${(props) => props.backgroundColor || 'transparent'};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
  z-index: 20;
  pointer-events: none;
`;

const CenterStyles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  touch-action: none;
`;

export const FixedLoader = ({
  backgroundColor,
  showMenu = false,
}: {
  backgroundColor?: string;
  showMenu?: boolean;
}) => {
  return (
    <FixedStyles backgroundColor={backgroundColor} showMenu={showMenu}>
      <Loader />
    </FixedStyles>
  );
};

export const CenteredLoader = () => {
  const outOfBoundsRef = useOutOfBoundsRef();
  useKeyListener({
    left: () => outOfBoundsRef.current.left(),
  });
  return (
    <CenterStyles>
      <Loader />
    </CenterStyles>
  );
};

export const Loader = ({ color = 'white' }: { color?: string }) => {
  return (
    <svg
      data-testid="loader"
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ animation: 'spin 1s linear infinite' }}
    >
      <path
        d="M81 45C81 64.8824 64.8824 81 45 81C25.1176 81 9 64.8824 9 45C9 25.1176 25.1176 9 45 9"
        stroke={color}
        strokeWidth="6.75"
        strokeLinecap="round"
      />
      <circle
        cx="45"
        cy="45"
        r="36"
        stroke={color}
        strokeOpacity="0.2"
        strokeWidth="6.75"
      />
    </svg>
  );
};
