import {
  ApolloProvider,
  AppContextProvider,
  Locale,
  offlineEvents,
  SearchProvider,
  toSearchQueryParams,
  useIsOffline,
  webStorage
} from '@sfstudios/shapeshifter';
import React, { useCallback, useEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { exitApp } from './components/AppExiter';
import { PlayerLazy } from './components/cards/Player/PlayerLazy';
import { PlayerOpenContext } from './components/cards/Player/PlayerOpenContext';
import { FocusContext } from './components/Focusable';
import { CenteredLoader } from './components/Loader';
import CountryPickerModal from './components/modals/CountryPickerModal';
import { DeepLinkContextProvider } from './context/DeepLinkContext';
import { MouseContextProvider } from './context/MouseContext';
import { FocusProvider } from './focus-engine/useFocusEngine';
import { useFavicon } from './hooks/useFavicon';
import { useHeadTitle } from './hooks/useHeadTitle';
import { getDirection } from './hooks/useKeyListener';
import { useKeyMapping } from './hooks/useKeyMapping';
import { useListenToMessageFromParent } from './hooks/useListenToMessageFromParent';
import { useScroll } from './hooks/useScroll';
import { useSentry } from './hooks/useSentry';
import { useSetupDeviceAndClient } from './hooks/useSetupDeviceAndClient';
import { useSyncBookmarks } from './hooks/useSyncBookmarks';
import { useToggleBrandAtKeyPress } from './hooks/useToggleBrandAtKeyPress';
import { Routes } from './Routes';
import { brand } from './utils/brand';
import { getLocale } from './utils/deviceInfo';
import { getEnvVariable } from './utils/getEnvVariable';
import { Platform } from './utils/platform';
import { postMessageToParent } from './utils/postMessageToParent';

/* eslint-disable import/first */
declare var __webpack_public_path__: string;
// eslint-disable-next-line
__webpack_public_path__ = getEnvVariable('REACT_APP_CDN_PATH', '/');

const GlobalStyle = createGlobalStyle`
  // Do not set any body background color here, as it will block the Tizen internal player from showing.
`;

// Add class to body to allow scoping styling that needs to live in CSS files to
// the current platform.
(() => {
  try {
    // Super old devices might not support classList.add, so we'll do things the
    // old fashioned way here.
    document.body.className = `platform-${Platform.OS}`;
  } catch {}
})();

if (Platform.lg()) {
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = '/webOSTV.js';
  head.appendChild(script);
}

export const CoreSettingsProvider: React.FC<{ locale: Locale }> = ({
  locale,
  children
}) => {
  const offlineStatusUpdater = useCallback(({ online }) => {
    offlineEvents.emit(online ? 'online' : 'offline');
  }, []);

  useListenToMessageFromParent({
    action: 'NETWORK_STATUS_CHANGED',
    callback: offlineStatusUpdater
  });

  useListenToMessageFromParent({
    action: 'CURRENT_NETWORK_STATUS',
    callback: offlineStatusUpdater
  });

  const { device, trackers, ready, client } = useSetupDeviceAndClient();

  if (!ready) return null;

  return (
    <ApolloProvider client={client}>
      <AppContextProvider
        brand={brand}
        locale={locale}
        storage={webStorage}
        device={device}
        trackers={trackers}
        initialNetworkStatus="online"
      >
        {children}
      </AppContextProvider>
    </ApolloProvider>
  );
};

const AppBootActions = () => {
  /**
   * Report booted on mount
   */
  useEffect(() => {
    postMessageToParent({ action: 'APP_BOOTED' });
  }, []);

  /**
   * We poll the current network status when we're offline, as sometimes the events get
   * lost from the OS reporting network status when coming back from sleep mode.
   *
   * By doing this we prevent the app thinking it's offline when it's actually online, just
   * because an offline sync didn't make it through.
   */
  const { offline } = useIsOffline();

  useEffect(() => {
    if (offline) {
      const interval = setInterval(() => {
        postMessageToParent({ action: 'CHECK_STATUS' });
      }, 2500);

      return () => {
        clearInterval(interval);
      };
    }
  }, [offline]);

  useScroll();

  const { onKeyMappingMessage } = useKeyMapping();
  useListenToMessageFromParent({
    action: 'KEY_MAPPING',
    callback: onKeyMappingMessage
  });

  useSyncBookmarks();
  useToggleBrandAtKeyPress();
  useFavicon();
  useHeadTitle();
  useSentry();

  return null;
};

const locale = getLocale();

const Root = () => {
  const playSlugState = useState('');
  const [playSlug, setPlaySlug] = playSlugState;
  const isPlaying = !!playSlug;

  // return <FocusStories />;

  return (
    <CoreSettingsProvider locale={locale || Locale.sv}>
      <SearchProvider queryParams={toSearchQueryParams(window.location.search)}>
        <DeepLinkContextProvider setPlaySlug={setPlaySlug}>
          <GlobalStyle />
          <MouseContextProvider>
            <AppBootActions />
            {locale ? (
              <PlayerOpenContext.Provider value={playSlugState}>
                {isPlaying ? (
                  <FocusProvider
                    getDirection={getDirection}
                    getRouteKey={() => 'player'}
                  >
                    <React.Suspense fallback={<CenteredLoader />}>
                      <PlayerLazy
                        key={playSlug}
                        slugWithQueryString={playSlug}
                      />
                    </React.Suspense>
                  </FocusProvider>
                ) : (
                  <FocusContext.Provider value={!isPlaying}>
                    <Routes />
                  </FocusContext.Provider>
                )}
              </PlayerOpenContext.Provider>
            ) : (
              <CountryPickerModal close={exitApp} />
            )}
          </MouseContextProvider>
        </DeepLinkContextProvider>
      </SearchProvider>
    </CoreSettingsProvider>
  );
};

export default Root;
