import { FeatureToggle, useFeatureToggle } from '@sfstudios/shapeshifter';
import { lazy, ReactNode, Suspense } from 'react';
import { Loader } from '../Loader';

const ProfilerLazy = lazy(() => import('./Profiler'));

export const SmartTvProfiler = ({ children }: { children: ReactNode }) => {
  const { enabled } = useFeatureToggle(FeatureToggle.PROFILING);

  if (!enabled) return <>{children}</>;

  return (
    <Suspense fallback={<Loader />}>
      <ProfilerLazy>{children}</ProfilerLazy>
    </Suspense>
  );
};
