import { Brand, brands, isBrand } from '@sfstudios/shapeshifter';
import { getEnvVariable } from './getEnvVariable';

export const brand = (() => {
  const brandEnv = getEnvVariable('REACT_APP_BRAND', 'SFAnytime');
  if (!isBrand(brandEnv)) {
    console.warn(`Invalid brand: ${brandEnv}. Available brands: ${brands}`);
    return Brand.SFAnytime;
  }
  return brandEnv;
})();
