import { ButtonProps, Colors, themeColor } from '@sfstudios/shapeshifter';
import styled, { useTheme } from 'styled-components';
import { allUnset, focusStyles } from '../../../utils/styleUtils';
import { BaseButton } from '../../Button';
import Icon, { IconName } from '../../Icon';

interface Props extends ButtonProps {
  onClick?: () => void;
  /** Overriding text color should only be done in edge cases */
  overrideTextColor?: keyof Colors;
}

export const Button = (props: Props) => {
  const { children, textOnly, iconStart, iconEnd, onClick, outlined } = props;

  return (
    <Wrapper onClick={onClick}>
      <BaseButton
        fillColor={getFillColor(props)}
        textColorName={getTextColor(props)}
        outlineColorName={outlined ? 'ui100' : undefined}
        iconStart={iconStart}
        iconEnd={iconEnd}
        textOnly={textOnly}
      >
        <Content>{children}</Content>
      </BaseButton>
    </Wrapper>
  );
};

type IconButtonProps = Omit<Props, 'children' | 'iconEnd' | 'iconStart'> & {
  icon: IconName;
  onClick: () => void;
};

const getTextColor = (
  props: Omit<Props, 'children'>
): keyof Colors | undefined => {
  const { outlined, textOnly, overrideTextColor } = props;
  if (overrideTextColor) return overrideTextColor;
  if (outlined) return 'ui100';
  if (textOnly) return 'ui100';
  return undefined;
};

const getFillColor = (props: Omit<Props, 'children'>) => {
  const { outlined, textOnly } = props;
  if (outlined) return 'transparent';
  if (textOnly) return 'transparent';
  return undefined;
};

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.button`
  ${allUnset}
  background: none;
  border-radius: 50px;
  ${focusStyles};
  scroll-margin: 40px;
`;

export const IconButton = (props: IconButtonProps) => {
  const { icon } = props;
  const theme = useTheme();
  const fillThemeColor = getTextColor(props);
  const fill = fillThemeColor && themeColor(fillThemeColor)({ theme });
  const size = 48;

  return (
    <IconButtonWrapper {...props}>
      <Icon name={icon} fill={fill} width={size} height={size} />
    </IconButtonWrapper>
  );
};

const IconButtonWrapper = styled.button<IconButtonProps>`
  ${allUnset}
  background-color: ${themeColor('ui700')};
  border-radius: 1000px;
  ${focusStyles};
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
