import { useAsset } from '@sfstudios/shapeshifter';
import { useEffect } from 'react';

export const useFavicon = () => {
  const { url } = useAsset({ name: 'favicon', height: 32 });
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
  }, [url]);
};
