import { useEffect, useState } from 'react';

export const useReady = () => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    requestAnimationFrame(() => {
      setReady(true);
    });
  }, []);
  return ready || null;
};
