import { EventTrackerListener, TrackerCategory } from '@sfstudios/shapeshifter';
import { EVENTS } from './events';

export const gtag: Gtag.Gtag = function (..._args: unknown[]) {
  if (window.dataLayer == null) {
    return;
  }

  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);

  return;
};

declare global {
  interface Window {
    dataLayer?: unknown[];
  }
}

const initializeGoogleAnalytics = ({ id }: { id: string }) => {
  polyfillGlobalThis();
  const firstScript = document.getElementsByTagName('script')[0];
  const scriptTag = document.createElement('script');

  scriptTag.async = true;
  scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  firstScript?.parentNode?.insertBefore(scriptTag, firstScript);

  if (window.dataLayer == null) {
    window.dataLayer = [];
  }

  gtag('js', new Date());
  gtag('config', id, { send_page_view: false, anonymize_ip: true });
};

// https://support.google.com/analytics/answer/9267735?hl=en
const listener: EventTrackerListener<EVENTS> = {
  id: 'google-analytics-v4',
  category: TrackerCategory.Required,
  onLoad: (context) => {
    const id = context?.configs?.GOOGLE_ANALYTICS_V4_ID;
    if (!id) return;
    initializeGoogleAnalytics({ id });
    context.ga.setLogger(({ event, params }) => {
      gtag('event', event, params);
    });
  },
  listener: (event, context) => {
    const { ga } = context;
    const { payload, type } = event;
    switch (type) {
      case 'buyButtonClick': {
        ga.sendEvent('add_to_cart', payload);
        break;
      }
      case 'playButtonClick': {
        ga.sendEvent(
          payload.isTrailer ? 'view_trailer' : 'video_start',
          payload
        );
        break;
      }
      case 'searchInputBlur': {
        ga.sendEvent('view_search_results', payload);
        break;
      }
      case 'listItemClick': {
        ga.sendEvent('select_item', payload);
        break;
      }
      case 'viewList': {
        ga.sendEvent('view_item_list', payload);
        break;
      }
      case '@shapeshifter-core/BOOT':
        gtag('event', 'page_view');
        break;

      case 'accountCreated':
        ga.sendEvent('sign_up', payload);
        break;

      case 'loggedIn':
        ga.sendEvent('login', payload);
        break;

      case 'addFavorite':
        ga.sendEvent('add_to_wishlist', payload);
        break;

      case 'notifyMe':
        ga.sendEvent('add_to_notification_list', { slug: payload.slug });
        break;

      case 'viewCdp': {
        ga.sendEvent('view_item', payload);
        break;
      }

      case 'routeChange':
        ga.sendEvent('page_view', payload);
        break;

      case 'TRANSACTION_COMPLETED': {
        ga.sendEvent('purchase', payload);
        break;
      }

      default:
        break;
    }
  }
};

/**
 * Polyfill for globalThis. Preventing crashes on some LG devices.
 * Seen in sentry from 2024-09-11: "globalThis is not defined" which is used in the google tag manager script.
 **/
const polyfillGlobalThis = () => {
  const firstScript = document.getElementsByTagName('script')[0];
  firstScript?.parentNode?.insertBefore(
    Object.assign(document.createElement('script'), {
      textContent:
        "window.globalThis = typeof globalThis === 'undefined' ? window : globalThis;"
    }),
    firstScript
  );
};

export default listener;
