import { getClosestFocusable } from './algorithm/closestElement';
import type { ElWithRect, FocusGroup, GetDirection } from './types';

let listeners: undefined | ((e: KeyboardEvent) => void) = undefined;

export const keyListeners = {
  update: ({
    focusableElements,
    focusedElement,
    focusGroups,
    onDirectionKey,
    getDirection,
    averageInputCharacterWidth
  }: {
    focusableElements: ElWithRect[];
    focusedElement: React.MutableRefObject<ElWithRect | undefined>;
    focusGroups: Record<string, FocusGroup>;
    getDirection: GetDirection;
    onDirectionKey: (
      r: ReturnType<typeof getClosestFocusable> & {
        previousFocusedElement: ElWithRect | undefined;
      }
    ) => void;
    averageInputCharacterWidth: number;
  }) => {
    const onKeyDown = (e: KeyboardEvent) => {
      const direction = getDirection(e);
      if (direction === undefined) return;

      const { closestElement, candidates } = getClosestFocusable({
        focusGroups,
        elements: focusableElements,
        fromElement: focusedElement,
        direction,
        averageInputCharacterWidth
      });
      const previousFocusedElement = focusedElement.current;
      (closestElement?.element.node as any)?.focus({ preventScroll });
      onDirectionKey({ candidates, previousFocusedElement, closestElement });
    };
    if (listeners) {
      document.body.removeEventListener('keydown', listeners);
      listeners = undefined;
    }
    document.body.addEventListener('keydown', onKeyDown);
    listeners = onKeyDown;
  }
};

// Ideally it would be nice to let the browser handle the scrolling
// to the newly focused element. It is not working properly when having
// partly hidden elements and nested scrollable elements. Because of this
// we are disabling the browser scroll and handling it ourselves.
export const preventScroll = true;
