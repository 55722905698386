import { themeColor } from '@sfstudios/shapeshifter';
import React, { createContext, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import { OutOfBoundsProvider } from '../../hooks/useOutOfBounds';
import { FocusContextProvider } from '../Focusable';

export const ModalWrapper = styled.div<{
  animate?: boolean;
  visible?: boolean;
  transparent?: boolean;
}>`
  @keyframes fadeIn {
    0% {
      transform: translateY(200%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  @keyframes fadeOut {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: ${(props) =>
    props.transparent ? 'transparent' : themeColor('ui900', 0.95)(props)};
  animation: ${(props) =>
    props.animate
      ? props.visible
        ? 'fadeIn 0.25s'
        : 'fadeOut 0.25s'
      : 'none'};
  animation-fill-mode: forwards;
`;

const portalRoot = document.getElementById('portal-root')!;

export const ModalContext = createContext(false);

export const Modal = ({
  children,
  animate = false,
  visible = true,
  transparent = false
}: {
  children: ReactNode;
  animate?: boolean;
  visible?: boolean;
  transparent?: boolean;
}) => {
  return ReactDOM.createPortal(
    <FocusContextProvider value={true}>
      <OutOfBoundsProvider>
        <ModalContext.Provider value={true}>
          <ModalWrapper
            animate={animate}
            visible={visible}
            transparent={transparent}
          >
            {children}
          </ModalWrapper>
        </ModalContext.Provider>
      </OutOfBoundsProvider>
    </FocusContextProvider>,
    portalRoot
  );
};
