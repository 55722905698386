import { Platform } from './platform';

type Direction = 'Up' | 'Down' | 'Right' | 'Left';

const dispatchArrowKeyEvent = (direction: Direction) => {
  const event: KeyboardEventInit = {
    key: `Arrow${direction}`,
    code: `Arrow${direction}`
  };

  if (Platform.OS === 'lg') {
    // keyIdentifier is needed for older LG versions, WebOS 3.5 and maybe others
    (event as any).keyIdentifier = direction;
  }

  document.body.dispatchEvent(new KeyboardEvent('keydown', event));
  document.body.dispatchEvent(new KeyboardEvent('keyup', event));
};

export const handleUpArrow = () => dispatchArrowKeyEvent('Up');
export const handleDownArrow = () => dispatchArrowKeyEvent('Down');
export const handleRightArrow = () => dispatchArrowKeyEvent('Right');
export const handleLeftArrow = () => dispatchArrowKeyEvent('Left');
