import { darkTheme, useL10n } from '@sfstudios/shapeshifter';
import { useState } from 'react';
import styled from 'styled-components';
import { useStartedFromDeepLink } from '../context/DeepLinkContext';
import { useIsCursorVisible } from '../context/MouseContext';
import { FocusGroup } from '../focus-engine/FocusGroup';
import { useHover } from '../hooks/useHover';
import { Input, useKey, useKeyListener } from '../hooks/useKeyListener';
import { useRowNavigation } from '../hooks/useKeyNavigation';
import { useNewFocusEngine } from '../newFocusEngineRoutes';
import { fontSize, whiteSpace } from '../theme';
import { ButtonProps } from '../types';
import { Platform } from '../utils/platform';
import { postMessageToParent } from '../utils/postMessageToParent';
import { getApproximateWebosVersion } from '../utils/webosUtils';
import { OutlinedButton, PrimaryButton, UnstyledHTMLButton } from './Button';
import { Focusable } from './Focusable';
import { Modal, ModalWrapper } from './modals/Modal';
import { Button } from './pages/Search/Button';

export const exitApp = () => {
  switch (Platform.OS) {
    case 'lg': {
      window.webOS?.platformBack();
      break;
    }
    case 'samsung': {
      postMessageToParent({ action: 'EXIT_APP' });
      break;
    }
    case 'vewd': {
      window.close();
      break;
    }
    case 'philips': {
      if (typeof SmartTvA_API != 'undefined') {
        SmartTvA_API.exit();
      } else {
        window.history.go(-999);
        window.close();
      }
      break;
    }
  }
};

const Styles = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: ${whiteSpace['10']};
  background: ${darkTheme.color.ui700};
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: ${fontSize.xl};
    margin: 0 0 ${whiteSpace['10']} 0;
    text-align: center;
  }
`;

/**
 * Wrap listener inside context of a modal wrapper to correctly
 * let row navigation to know it is inside a modal.
 */
const InnerAppExiterLegacy = ({ cancel }: { cancel: () => void }) => {
  const focusedRow = useRowNavigation(2);
  const { t } = useL10n();
  const [yesHoverRef, isYesHovered] = useHover();
  const [cancelHoverRef, isCancelHovered] = useHover();
  const isCursorVisible = useIsCursorVisible();

  useKeyListener({
    back: () => {
      cancel();
    }
  });

  return (
    <Styles>
      <h3>{t('navigation.confirm_close_app_title')}</h3>

      <Focusable<ButtonProps>
        as={UnstyledHTMLButton}
        focused={isCursorVisible ? isYesHovered : focusedRow === 0}
        onClick={exitApp}
      >
        <div ref={yesHoverRef}>
          <PrimaryButton width={400}>{t('glossary.yes')}</PrimaryButton>
        </div>
      </Focusable>
      <Focusable<ButtonProps>
        as={UnstyledHTMLButton}
        focused={isCursorVisible ? isCancelHovered : focusedRow === 1}
        onClick={cancel}
      >
        <div ref={cancelHoverRef}>
          <OutlinedButton width={400}>{t('glossary.no')}</OutlinedButton>
        </div>
      </Focusable>
    </Styles>
  );
};

const InnerAppExiterNewFocusEngine = ({ cancel }: { cancel: () => void }) => {
  const { t } = useL10n();

  useKey({
    back: () => {
      cancel();
    }
  });

  return (
    <Styles>
      <h3>{t('navigation.confirm_close_app_title')}</h3>
      <Button primary onClick={exitApp}>
        {t('glossary.yes')}
      </Button>
      <Button onClick={cancel} outlined>
        {t('glossary.no')}
      </Button>
    </Styles>
  );
};

const useAppExit = () => {
  const [show, setShow] = useState(false);
  const startedFromDeepLink = useStartedFromDeepLink();

  const input: Input = {
    back: (_, numListeners) => {
      if (show) {
        exitApp();
        return;
      }

      if (
        /**
         * Samsung wants the app to exit without prompting if the session
         * was initiated via a deep link. So, instead of prompting, we just
         * exit directly in Samsung here.
         */
        Platform.samsung() &&
        numListeners === 1 &&
        startedFromDeepLink
      ) {
        exitApp();
        return;
      }

      /**
       * LG webOS 22 and above has a native app exit popup that is shown when
       * exiting the app. Therefore we don't want to show our own prompt.
       */
      const webOSVersion = getApproximateWebosVersion();
      if (
        Platform.lg() &&
        numListeners === 1 &&
        webOSVersion &&
        webOSVersion >= 22
      ) {
        exitApp();
        return;
      }

      /**
       * Show the prompt if this is the only listener left for "back"
       * (means all other content is unmounted) and we're not already
       * showing the prompt.
       */
      if (numListeners === 1 && !show) {
        setShow(true);
        return;
      }
    }
  };
  return { show, setShow, input };
};

const AppExiterLegacy = () => {
  const { input, show, setShow } = useAppExit();

  useKeyListener(input);

  if (!show) {
    return null;
  }

  return (
    <Modal>
      <InnerAppExiterLegacy cancel={() => setShow(false)} />
    </Modal>
  );
};

const AppExiterNewFocusEngine = () => {
  const { input, show, setShow } = useAppExit();

  useKey(input);

  if (!show) return null;

  return (
    <FocusGroup id="modal" overlay preferFocus>
      <ModalWrapper>
        <InnerAppExiterNewFocusEngine cancel={() => setShow(false)} />
      </ModalWrapper>
    </FocusGroup>
  );
};

export const AppExiter = () => {
  return useNewFocusEngine() ? (
    <AppExiterNewFocusEngine />
  ) : (
    <AppExiterLegacy />
  );
};
