import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useFocusEngine } from './useFocusEngine';

export const FocusGroupContext = React.createContext<{ id: string }>(
  {} as never
);

export type FocusGroupProps = {
  id: string;
  children: React.ReactNode;
  style?: React.CSSProperties;

  /** Short for blockMovingUp=true, blockMovingDown=true, blockMovingDown=true, blockMovingRight=true */
  overlay?: boolean;
  blockMovingUp?: boolean;
  blockMovingDown?: boolean;
  blockMovingLeft?: boolean;
  blockMovingRight?: boolean;

  /**
   * Controls scrolling to a element outside of visible field in horizontal direction.
   * It can be useful when scroll-margin doesn't work. For example in nested scroll views.
   * */
  horizontalAutoScrollPosition?: ScrollLogicalPosition;
  /**
   * Controls scrolling to a element outside of visible field in vertical direction.
   * It can be useful when scroll-margin doesn't work. For example in nested scroll views.
   **/
  verticalAutoScrollPosition?: ScrollLogicalPosition;

  /** Focus is initially set to this focus group */
  preferFocus?: boolean;

  /** Elements within same layer will be preferred when selecting next focus */
  layer?: string;

  /**
   * Newly mounted components automatically gets focus. Set this to false to
   * prevent this behavior.
   **/
  autoFocusOnMount?: boolean;
};

export const FocusGroup = (props: FocusGroupProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    id,
    overlay,
    blockMovingDown,
    blockMovingLeft,
    blockMovingRight,
    blockMovingUp,
    horizontalAutoScrollPosition,
    verticalAutoScrollPosition,
    preferFocus,
    layer,
    autoFocusOnMount,
    ...rest
  } = props;
  const {
    updateElements,
    updateChildrenInGroup,
    onGroupMount,
    onGroupFocus,
    updateFocus
  } = useFocusEngine();

  useEffect(() => {
    if (!ref.current) return;
    return onGroupMount(ref.current);
  }, [onGroupMount]);

  // Update on scroll
  useEffect(() => {
    if (!ref.current) return;
    const div = ref.current;
    const onEvent = () => {
      updateElements();
    };
    div.addEventListener('scrollend', onEvent);
    return () => {
      div.removeEventListener('scrollend', onEvent);
    };
  }, [updateElements]);

  // Update on resize (elements added, removed, reordered etc)
  useEffect(() => {
    if (!ref.current) return;
    const mutationObserver = new MutationObserver(() => {
      if (!ref.current) return;
      updateElements();
      updateChildrenInGroup(ref.current);
      updateFocus();
    });
    mutationObserver.observe(ref.current, {
      childList: true,
      subtree: true
    });
    return () => mutationObserver.disconnect();
  }, [updateFocus, updateChildrenInGroup, updateElements]);

  return (
    <Container
      ref={ref as any}
      data-focus-group={id}
      data-focus-block-up={blockMovingUp || overlay}
      data-focus-block-down={blockMovingDown || overlay}
      data-focus-block-left={blockMovingLeft || overlay}
      data-focus-block-right={blockMovingRight || overlay}
      data-vertical-auto-scroll-position={verticalAutoScrollPosition}
      data-horizontal-auto-scroll-position={horizontalAutoScrollPosition}
      data-auto-focus-on-mount={autoFocusOnMount}
      data-prefer-focus={preferFocus}
      data-layer={layer}
      tabIndex={0}
      onFocus={(e) => onGroupFocus(e, id)}
      {...rest}
    />
  );
};

const Container = styled.div`
  div > :hover .show-on-hover {
    display: flex;
  }
`;
