import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

type DrawerData = {
  type: 'qrCode';
  url: string;
  titleKey?: string;
};

const drawerState = atom<{
  visible: boolean;
  data: DrawerData;
}>({
  key: 'drawerState',
  default: {
    visible: false,
    data: { type: 'qrCode', url: '' }
  }
});

export const useDrawerState = () => {
  return useRecoilValue(drawerState);
};

export const useDrawer = () => {
  const set = useSetRecoilState(drawerState);
  const open = useCallback((data: DrawerData) => set({ visible: true, data }), [
    set
  ]);
  const close = useCallback(
    () => set((data) => ({ ...data, visible: false })),
    [set]
  );
  return { open, close };
};
