import { css } from 'styled-components';

interface BreakpointTemplate {
  (first: TemplateStringsArray): any;
}
interface Breakpoints {
  tvLowRes: BreakpointTemplate;
  giant: BreakpointTemplate;
  desktop: BreakpointTemplate;
  tablet: BreakpointTemplate;
  mobile: BreakpointTemplate;
}

export const breakpointSizes = {
  tvLowRes: 1280,
  giant: 1170,
  desktop: 992,
  tablet: 768,
  mobile: 576
};
// Iterate through the sizes and create a media template
const mediaGenerator = (sizes: any) =>
  Object.keys(sizes)
    .sort()
    .reduce((acc, label) => {
      // @ts-ignore
      acc[label] = (...args: any) => {
        // @ts-ignore
        const a = css(...args);
        return css`
          @media only screen and (max-width: ${sizes[label] / 16}em) {
            ${a};
          }
        `;
      };

      return acc;
    }, {});

/** @deprecated */
export const breakpoints = mediaGenerator(breakpointSizes) as Breakpoints;
