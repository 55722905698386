import {
  ButtonProps,
  CountryOfResidence,
  darkTheme,
  Locale,
  themeColor,
  useL10n
} from '@sfstudios/shapeshifter';
import React from 'react';
import styled from 'styled-components/macro';
import { useIsCursorVisible } from '../../context/MouseContext';
import { useHover } from '../../hooks/useHover';
import { useKeyListener } from '../../hooks/useKeyListener';
import {
  useColumnNavigation,
  useRowNavigation
} from '../../hooks/useKeyNavigation';
import { fontSize, lineHeight, whiteSpace } from '../../theme';
import { saveLocale } from '../../utils/deviceInfo';
import { isLowEnd } from '../../utils/platform';
import { MinimalButton, UnstyledHTMLButton } from '../Button';
import { Focusable } from '../Focusable';
import Icon from '../Icon';
import { Modal } from './Modal';
import { useRemoteControllerFocus } from '../../hooks/useRemoteControllerFocus';

const ModalContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${themeColor('ui900')};
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    display: flex;
    margin-bottom: ${whiteSpace['10']};
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 55%;
  }
`;

const ModalTitle = styled.h2`
  height: 88px; // in case translation isn't immediately available
  margin: 0 0 ${whiteSpace['14']} 0;
  font-size: ${fontSize['3xl']};
  color: ${darkTheme.color.ui200};
  line-height: ${lineHeight.snug};
  text-align: center;
`;

type SvgFlagName = 'flag-se' | 'flag-fi' | 'flag-no' | 'flag-dk';

const corToSvgName: { [key in CountryOfResidence]: SvgFlagName } = {
  se: 'flag-se',
  fi: 'flag-fi',
  no: 'flag-no',
  dk: 'flag-dk'
};

const corToLocale: { [key in CountryOfResidence]: Locale } = {
  se: Locale.sv,
  fi: Locale.fi,
  no: Locale.no,
  dk: Locale.da
};

const CountryRowStyles = styled.li<{ focused: boolean }>`
  outline: ${(props) => (props.focused ? '4px solid white' : 'none')};
  transform: scale(${(props) => (props.focused ? '1.1' : 1)});
  ${() => (isLowEnd ? '' : 'transition: transform 0.125s;')}
  margin-right: ${whiteSpace['8']};
  button {
    vertical-align: bottom;
    line-height: 0;
  }
`;

const CountryRow = ({
  country,
  focused
}: {
  country: CountryOfResidence;
  focused: boolean;
}) => {
  const [hoverRef, isHovered] = useHover();
  const isCursorVisible = useIsCursorVisible();
  const isFocused = isCursorVisible ? isHovered : focused;
  return (
    <CountryRowStyles focused={isFocused}>
      <Focusable<ButtonProps>
        as={UnstyledHTMLButton}
        focused={isFocused}
        aria-label={country}
        onClick={() => {
          saveLocale(corToLocale[country]);
          window.location.reload();
        }}
      >
        <div ref={hoverRef}>
          <Icon name={corToSvgName[country]} width={200} height={150} />
        </div>
      </Focusable>
    </CountryRowStyles>
  );
};

const CountryPickerModal: React.FC<{
  close?: () => void;
}> = ({ close }) => {
  const nRows = close ? 2 : 1;
  const focusedRow = useRowNavigation(nRows);
  const isColumnNavActive = !close || focusedRow === 0;
  const focusedCol = useColumnNavigation(4, isColumnNavActive);
  const hasColOrRow = useRemoteControllerFocus();
  const { t, isLoading: isLoadingL10n } = useL10n();
  const [ref, isHovered] = useHover();
  const isCursorVisible = useIsCursorVisible();
  useKeyListener({
    back: () => {
      close && close();
    }
  });

  const titleText = t('settings.my_profile.choose_country', {
    defaultValue: ''
  });
  return (
    <Modal>
      <ModalContent>
        {!isLoadingL10n && (
          <div>
            <ModalTitle>{titleText}</ModalTitle>
            <ul>
              <CountryRow
                focused={
                  hasColOrRow(0, focusedRow) && hasColOrRow(0, focusedCol)
                }
                country={CountryOfResidence.se}
              />
              <CountryRow
                focused={
                  hasColOrRow(0, focusedRow) && hasColOrRow(1, focusedCol)
                }
                country={CountryOfResidence.no}
              />
              <CountryRow
                focused={
                  hasColOrRow(0, focusedRow) && hasColOrRow(2, focusedCol)
                }
                country={CountryOfResidence.fi}
              />
              <CountryRow
                focused={
                  hasColOrRow(0, focusedRow) && hasColOrRow(3, focusedCol)
                }
                country={CountryOfResidence.dk}
              />
            </ul>
            {close && (
              <Focusable<ButtonProps>
                as={UnstyledHTMLButton}
                onClick={close}
                focused={isCursorVisible ? isHovered : focusedRow === 1}
              >
                <div ref={ref}>
                  <MinimalButton width={400} iconStart="arrow-left">
                    {t('glossary.close_modal', { defaultValue: 'Close' })}
                  </MinimalButton>
                </div>
              </Focusable>
            )}
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};

export default CountryPickerModal;
