import { useLocation } from 'react-router-dom';
import { PageRoutes } from './types';

const routesWithNewFocusEngine = [
  PageRoutes.SearchFilter,
  PageRoutes.RangeFilter,
  PageRoutes.More,
  PageRoutes.Search
];

export const useNewFocusEngine = () => {
  const location = useLocation();
  return routesWithNewFocusEngine.some((r) => {
    const routeWithoutParams = r
      .split('/')
      .filter((s) => !s.startsWith(':'))
      .join('/');
    return location.pathname.startsWith(routeWithoutParams);
  });
};
