import { assertUnreachable } from '@sfstudios/shapeshifter';
import { getEnvVariable } from './getEnvVariable';
import { getSamsungVersion } from './tizenUtils';
import { getApproximateWebosVersion } from './webosUtils';

type Platforms = 'samsung' | 'lg' | 'vewd' | 'philips';

interface IPlatform {
  OS: Platforms | null;
  samsung(): boolean;
  philips(): boolean;
  lg(): boolean;
  vewd(): boolean;
  isLowEnd(): boolean;
  isModern(): boolean;
  supportsScroll(): boolean;
  supportsMouse(): boolean;
}

let currentPlatform: Platforms | null = null;

const platformFromEnv = getEnvVariable(
  'REACT_APP_PLATFORM',
  'unknown-tv'
) as Platforms;

switch (platformFromEnv) {
  case 'philips':
  case 'samsung':
  case 'vewd':
  case 'lg':
    currentPlatform = platformFromEnv;
    break;

  default: {
    assertUnreachable(platformFromEnv);
  }
}

export const Platform: IPlatform = {
  OS: currentPlatform,
  samsung: () => currentPlatform === 'samsung',
  lg: () => currentPlatform === 'lg',
  vewd: () => currentPlatform === 'vewd',
  philips: () => currentPlatform === 'philips',
  isLowEnd: () => {
    switch (currentPlatform) {
      case 'lg': {
        const webosVersion = getApproximateWebosVersion();
        if (webosVersion !== null && webosVersion < 4) {
          return true;
        }
        break;
      }
      case 'samsung': {
        const samsungVersion = getSamsungVersion();
        if (samsungVersion !== null && samsungVersion < 3) {
          return true;
        }
        break;
      }
    }

    return false;
  },
  isModern: () => {
    switch (currentPlatform) {
      case 'lg': {
        // TBD - what is the modern version of webOS?
        // const webosVersion = getWebosVersion();
        break;
      }
      case 'samsung': {
        const samsungVersion = getSamsungVersion();
        if (samsungVersion !== null && samsungVersion >= 6) {
          return true;
        }
        break;
      }
    }
    return false;
  },
  supportsMouse: () => currentPlatform === 'lg' || currentPlatform === 'vewd',
  supportsScroll: () => currentPlatform === 'lg' || currentPlatform === 'vewd'
};

export const isLowEnd = Platform.isLowEnd();
