import styled from 'styled-components';
import { fontSize, whiteSpace } from '../../../theme';
import { themeColor } from '@sfstudios/shapeshifter';
import { allUnset, focusStyles } from '../../../utils/styleUtils';
import Typography from '../../Typography';

export const Heading = styled(Typography).attrs({ variant: 'largeTitleBold' })`
  font-size: ${fontSize['3xl']};
  margin-top: 0;
  margin-bottom: 0;
`;

export const SubHeading = styled(Typography).attrs({
  variant: 'largeTitleBold'
})`
  font-size: ${fontSize['xl']};
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 50%;
  padding-bottom: ${whiteSpace['6']};
`;

export const ColumnWrapper = styled.div`
  background-color: ${themeColor('ui800')};
  padding-right: ${whiteSpace['8']};
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const ButtonWrapper = styled.button`
  ${allUnset}
  ${focusStyles}
`;

export const TableWrapper = styled.div`
  padding: 0;
  display: flex;
`;

export const ButtonBody = styled.div`
  color: ${themeColor('ui700')};
  display: flex;
  text-align: left;
  height: 100%;
  flex-direction: row;
  align-items: center;
  padding: ${whiteSpace['8']};
`;

export const ButtonLabel = styled.div`
  color: ${themeColor('ui100')};
  font-size: ${fontSize.base};
  font-weight: 400;
`;

export const ButtonOutline = styled.div`
  background-color: ${themeColor('ui700')};
  border-radius: 4px;
  height: 112px;
  align-items: flex-start;
`;

export const IconLeft = styled.div`
  color: ${themeColor('ui700')};
  margin-right: 0;
  margin-left: auto;
`;
