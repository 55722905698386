import { useCallback, useState } from 'react';

type THook = [(value: HTMLElement | null) => void, boolean];

export const useHover = (): THook => {
  const [hovered, setHovered] = useState(false);
  const setHoverListeners = useCallback((node: HTMLElement | null) => {
    const handleMouseOver = (): void => setHovered(true);
    const handleMouseOut = (): void => setHovered(false);
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, []);
  return [setHoverListeners, hovered];
};
