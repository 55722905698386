import { useEffect } from 'react';
import { MessageFromTVPackage } from '../contract/CommunicationContract';

interface MakeParentListenerConfig<T extends MessageFromTVPackage> {
  action: T['action'];
  callback: (event: T) => void;
}

type UnsubFn = () => void;

export const makeParentListener = <T extends MessageFromTVPackage>({
  action,
  callback
}: MakeParentListenerConfig<T>): UnsubFn => {
  const handler = (ev: MessageEvent) => {
    if (ev.data?.action === action) {
      callback(ev.data as T);
    }
  };

  window.addEventListener('message', handler);

  return () => {
    window.removeEventListener('message', handler);
  };
};

export const useListenToMessageFromParent = <T extends MessageFromTVPackage>({
  action,
  callback
}: MakeParentListenerConfig<T>): void => {
  useEffect(() => {
    return makeParentListener({ action, callback });
  }, [action, callback]);
};
