import { useBrandOverride } from '@sfstudios/shapeshifter';
import { useEffect } from 'react';
import { getEnvVariable } from '../utils/getEnvVariable';

const canToggleBrand =
  getEnvVariable('REACT_APP_ENV_NAME', 'production') !== 'production' ||
  process.env.NODE_ENV === 'development';

export const useToggleBrandAtKeyPress = () => {
  const { toggleBrand } = useBrandOverride({
    reload: () => window.location.reload()
  });

  useEffect(() => {
    if (!canToggleBrand) return;

    const l = (e: KeyboardEvent) => {
      if (e.code === 'KeyB' && !e.shiftKey && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        toggleBrand();
      }
    };
    document.addEventListener('keydown', l);
    return () => document.removeEventListener('keydown', l);
  }, [toggleBrand]);
};
