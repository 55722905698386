import React from 'react';
import { ICONS as svg } from '@sfstudios/shapeshifter';

export type IconName = keyof typeof svg;

export interface IconProps extends Partial<React.SVGProps<SVGSVGElement>> {
  name: IconName;
  height?: string | number;
  width?: string | number;
  fill?: string;
  size?: string | number;
}

const Icon = ({ name, size, ...otherProps }: IconProps) => {
  const iconSource = svg[name];
  const width = size ?? otherProps.width;
  const height = size ?? otherProps.height;
  return (
    <svg
      dangerouslySetInnerHTML={{ __html: iconSource ?? '' }}
      {...otherProps}
      width={width}
      height={height}
    ></svg>
  );
};

export default Icon;
