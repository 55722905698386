import {
  ButtonProps,
  darkTheme,
  useIsOffline,
  useL10n
} from '@sfstudios/shapeshifter';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useKeyListener } from '../../hooks/useKeyListener';
import { isLowEnd } from '../../utils/platform';
import { PrimaryButton, UnstyledHTMLButton } from '../Button';
import { PlayerOpenContext } from '../cards/Player/PlayerOpenContext';
import { Focusable } from '../Focusable';
import Typography from '../Typography';

const OfflinePrompt = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 48px;
  background-color: ${darkTheme.color.black};
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  ${() => (isLowEnd ? '' : 'transition: opacity 300ms ease-in-out;')}
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
`;

const OfflineBar = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 16px;
  width: 66%;
  background-color: ${darkTheme.color.ui800};
  z-index: 100;
  margin: 0 auto;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  ${() => (isLowEnd ? '' : 'transition: opacity 300ms ease-in-out;')}
  pointer-events: none;
`;

const SubtitleContainer = styled.div`
  margin-top: 8px;
`;

const OfflineKeyListener = ({ close }: { close: () => void }) => {
  useKeyListener({
    back: () => {
      close();
    },
    enter: () => {
      close();
    }
  });

  return null;
};

export const NetworkOfflinePrompt = () => {
  const { t } = useL10n();
  const { offline } = useIsOffline();
  const [playingSlug, setPlayingSlug] = useContext(PlayerOpenContext);
  const [visible, setVisible] = useState(offline);

  useEffect(() => {
    if (offline && playingSlug !== '') {
      // Exit player if we went offline and we're playing something
      setPlayingSlug('');
    }
  }, [offline, playingSlug, setPlayingSlug]);

  useEffect(() => {
    if (offline) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [offline, setVisible]);

  const close = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return visible ? (
    <OfflinePrompt visible={offline && visible}>
      <Typography variant="largeTitleBold">{t('player.offline')}</Typography>
      <SubtitleContainer>
        <Typography variant="titleBold">
          {t('error.app.offline.message_autocloses')}
        </Typography>
      </SubtitleContainer>
      <SubtitleContainer>
        <Focusable<ButtonProps>
          as={UnstyledHTMLButton}
          focused={true}
          onClick={() => {
            setVisible(false);
          }}
        >
          <PrimaryButton width={400}>{t('glossary.continue')}</PrimaryButton>
        </Focusable>
      </SubtitleContainer>
      <OfflineKeyListener close={close} />
    </OfflinePrompt>
  ) : (
    <OfflineBar visible={offline && !visible}>
      <Typography variant="titleRegular">{t('player.offline')}</Typography>
    </OfflineBar>
  );
};
