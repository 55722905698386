import { themeColor, useL10n, useQrUrl } from '@sfstudios/shapeshifter';
import styled from 'styled-components';
import Typography from '../../components/Typography';
import { gap } from '../styleUtils';
import { Drawer } from './Drawer';
import { useDrawer, useDrawerState } from './useDrawer';

export const OpenLinkDrawer = () => {
  const { close } = useDrawer();
  const { visible, data } = useDrawerState();
  const qrUrl = useQrUrl(data.url);
  const displayUrl = data.url.replace(/https?:\/\//, '');
  const { t } = useL10n();

  return (
    <Drawer
      visible={visible}
      onClose={close}
      titleKey={data.titleKey ?? 'glossary.open_in_browser_button'}
    >
      <TextWrapper>
        <Subtitle color="white" variant="largeTitleBold">
          {t('remote.pay.go_to') + ' '}
        </Subtitle>
        <Subtitle color="cta" variant="largeTitleBold">
          {displayUrl}
        </Subtitle>
      </TextWrapper>
      <Typography color="offWhite" variant="largeTitleRegular">
        {t('remote.pay.url_help_no_code')}
      </Typography>
      <DividerWrapper>
        <DividerLine />
        <DividerText variant="largeTitleRegular">
          {t('remote.pay.or_text')}
        </DividerText>
        <DividerLine />
      </DividerWrapper>
      <TextWrapper>
        <Subtitle color="white" variant="largeTitleBold">
          {t('remote.pay.point_camera_at') + ' '}
        </Subtitle>
        <Subtitle color="cta" variant="largeTitleBold">
          {t('remote.pay.qr_code')}
        </Subtitle>
      </TextWrapper>
      <Typography color="offWhite" variant="largeTitleRegular">
        {t('remote.pay.qr_help_info_text_no_code', { url: displayUrl })}
      </Typography>
      <QRWrapper>
        <QrImage src={qrUrl} />
      </QRWrapper>
    </Drawer>
  );
};

const Subtitle = styled(Typography)`
  font-size: 30px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  margin-top: 5px;
  margin-bottom: 5px;
  ${gap(8, 'row')};
`;

const DividerLine = styled.div`
  height: 1px;
  margin-horizontal: 2px;
  flex: 1;
  align-self: center;
  background-color: rgba(255, 255, 255, 0.15);
`;

const DividerText = styled(Typography)`
  align-self: center;
  padding-left: 10px;
  padding-right: 10px;
  color: ${themeColor('ui300')};
`;

const QRWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 32px;
`;

const QrImage = styled.img`
  width: 240px;
  height: 240px;
`;

const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;
