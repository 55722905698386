import * as Sentry from '@sentry/browser';
import { useL10n } from '@sfstudios/shapeshifter';
import React, { useCallback, useState } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import styled from 'styled-components';
import { useKeyListener } from '../hooks/useKeyListener';
import { useRowNavigation } from '../hooks/useKeyNavigation';
import { fontSize, whiteSpace } from '../theme';
import { ButtonProps } from '../types';
import { history } from '../utils/historySingleton';
import { OutlinedButton, PrimaryButton, UnstyledHTMLButton } from './Button';
import { Focusable } from './Focusable';
import Typography from './Typography';

const Styles = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${whiteSpace['10']};
  p {
    text-align: center;
    font-size: ${fontSize['lg']};
  }
`;

const ErrorContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

const MessageContainer = styled.div`
  margin-bottom: 32px;
  margin-top: 64px;
`;

const ButtonContainer = styled.div`
  margin-bottom: 8px;
`;

const Inner = ({ retry, error }: { retry: () => void; error?: Error }) => {
  const { t } = useL10n();
  const focusedRow = useRowNavigation(error ? 3 : 2);
  const [showError, setShowError] = useState(false);

  const goBack = useCallback(() => {
    history.goBack();
  }, []);

  useKeyListener({
    back: goBack,
    left: goBack
  });

  const errorMessage = error?.message;

  return (
    <Styles>
      <MessageContainer>
        <p>{t('error.app.something_went_wrong')}</p>
      </MessageContainer>
      <ButtonContainer>
        <Focusable<ButtonProps>
          focused={focusedRow === 0}
          as={UnstyledHTMLButton}
          onClick={() => {
            /**
             * Because we currently have issues with errors around loading
             * chunks in prod, we'll go ahead and do a full page refresh here on
             * retry when we detect that a chunk failed loading.
             */
            if (
              errorMessage &&
              errorMessage.toLowerCase().startsWith('loading chunk ')
            ) {
              window.location.reload();
            } else {
              retry();
            }
          }}
        >
          <PrimaryButton>{t('error.generic.button_retry')}</PrimaryButton>
        </Focusable>
      </ButtonContainer>
      <ButtonContainer>
        <Focusable<ButtonProps>
          focused={focusedRow === 1}
          as={UnstyledHTMLButton}
          onClick={goBack}
        >
          <OutlinedButton>{t('glossary.back_navigation')}</OutlinedButton>
        </Focusable>
      </ButtonContainer>

      {error ? (
        <>
          <ButtonContainer>
            <Focusable<ButtonProps>
              focused={focusedRow === 2}
              as={UnstyledHTMLButton}
              onClick={() => setShowError((lastVal) => !lastVal)}
            >
              <OutlinedButton>{t('error.app.show_error')}</OutlinedButton>
            </Focusable>
          </ButtonContainer>
          {showError ? (
            <ErrorContainer>
              <Typography>{error.message}</Typography>
            </ErrorContainer>
          ) : null}
        </>
      ) : null}
    </Styles>
  );
};

export const ErrorBoundaryWrapper: React.FC<{}> = ({ children }) => {
  const onError = useCallback((error: Error) => {
    Sentry.captureException(error);
  }, []);

  const fallbackRender = useCallback(
    ({ error, resetErrorBoundary }: FallbackProps) => {
      return <Inner error={error} retry={resetErrorBoundary} />;
    },
    []
  );

  return (
    <ErrorBoundary onError={onError} fallbackRender={fallbackRender}>
      {children}
    </ErrorBoundary>
  );
};
