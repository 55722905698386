import { ApolloClient, EventTrackerListener } from '@sfstudios/shapeshifter';
import { EVENTS } from './events';
import googleAnalyticsV4Tracker from './googleAnalyticsV4Tracker';
import splunkTracker from './splunkTracker';

export const getTrackers = (
  client: ApolloClient
): EventTrackerListener<EVENTS>[] => {
  if ((window as any).Cypress) return [];
  return [googleAnalyticsV4Tracker, splunkTracker(client)];
};
