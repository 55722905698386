import { useContext, useEffect } from 'react';
import { FocusContext } from '../components/Focusable';
import { ModalContext } from '../components/modals/Modal';
import { Platform } from '../utils/platform';

type Func = (e?: KeyboardEvent, numListeners?: number) => void;

export type Input = {
  up?: Func;
  left?: Func;
  right?: Func;
  down?: Func;
  enter?: Func;
  back?: Func;
  toggleplay?: Func;
  pause?: Func;
  play?: Func;
  rewind?: Func;
  forward?: Func;
  stop?: Func;
};

type BoundFunctions = {
  up: Func[];
  left: Func[];
  right: Func[];
  down: Func[];
  enter: Func[];
  back: Func[];
  toggleplay: Func[];
  pause: Func[];
  play: Func[];
  rewind: Func[];
  forward: Func[];
  stop: Func[];
};

const nonModalListeners: BoundFunctions = {
  up: [],
  down: [],
  right: [],
  left: [],
  enter: [],
  back: [],
  toggleplay: [],
  pause: [],
  play: [],
  rewind: [],
  forward: [],
  stop: []
};
const modalListeners: BoundFunctions = {
  up: [],
  down: [],
  right: [],
  left: [],
  enter: [],
  back: [],
  toggleplay: [],
  pause: [],
  play: [],
  rewind: [],
  forward: [],
  stop: []
};

/**
 * We map this to the Samsung buttons so we listen to the right keycodes
 * depending on Tizen version/type of remote.
 */
let customKeyMapping: { [keyName: string]: number[] } = {};

export const setCustomKeyMapping = (keyMap: {
  [keyName: string]: number[];
}) => {
  customKeyMapping = keyMap;
};

const findKeyNameForKeyCode = (targetKeyCode: number): string | undefined => {
  for (const [keyName, keyCodes] of Object.entries(customKeyMapping)) {
    if (keyCodes.includes(targetKeyCode)) {
      return keyName;
    }
  }
};

export const eventToCode = (
  e: KeyboardEvent & { keyIdentifier?: string }
): keyof Input | undefined => {
  const keyIdentifier: string | undefined =
    findKeyNameForKeyCode(e.keyCode) ?? e.keyIdentifier;

  if (keyIdentifier) {
    switch (keyIdentifier) {
      case 'XF86Back':
        return 'back';
      case 'XF86PlayBack':
      case 'MediaPlayPause':
        return 'toggleplay';
      case 'MediaPause':
        return 'pause';
      case 'MediaPlay':
        return 'play';
      case 'MediaRewind':
        return 'rewind';
      case 'MediaForward':
      case 'MediaFastForward':
        return 'forward';
      case 'MediaStop':
        return 'stop';
      default:
        return keyIdentifier.toLowerCase() as keyof Input;
    }
  }

  const codeOrKey =
    typeof e.code !== 'undefined' && e.code !== '' ? e.code : e.key;

  switch (codeOrKey) {
    case 'ArrowDown':
      return 'down';
    case 'ArrowUp':
      return 'up';
    case 'ArrowLeft':
      return 'left';
    case 'ArrowRight':
      return 'right';
    case 'Enter':
      return 'enter';
    case 'KeyW':
      return 'play';
    case 'KeyS':
      return 'pause';
    case 'KeyA':
      return 'rewind';
    case 'KeyD':
      return 'forward';
    case 'KeyE':
    case 'XF86PlayBack':
      return 'toggleplay';
    case 'Escape':
    case 'XF86Back':
      return 'back';
  }
};

export const getDirection = (e: KeyboardEvent) => {
  const code = eventToCode(e);
  const direction = (() => {
    switch (code) {
      case 'left':
      case 'right':
      case 'up':
      case 'down':
        return code;
      default:
        return undefined;
    }
  })();
  return direction;
};

let modalsActive = 0;

export const triggerListeners = (key: keyof Input, e?: KeyboardEvent) => {
  const handlers = modalsActive ? modalListeners[key] : nonModalListeners[key];
  if (handlers) {
    handlers.forEach((h) => h(e, handlers.length));
  }
};

document.body.addEventListener('keydown', (e) => {
  const code = eventToCode(e);
  if (!code) return;

  const actionsToTake = (): {
    preventDefault: boolean;
    shouldTriggerListeners: boolean;
  } => {
    switch (code) {
      case 'down':
      case 'left':
      case 'right':
      case 'up':
        return { preventDefault: true, shouldTriggerListeners: true };
      case 'back': {
        if (Platform.philips()) {
          // Use philips default behavior for deleting characters in input fields
          if (isInput(e)) {
            return { preventDefault: false, shouldTriggerListeners: false };
          }
          return { preventDefault: true, shouldTriggerListeners: true };
        }
        if (Platform.vewd()) {
          return { preventDefault: true, shouldTriggerListeners: true };
        }
      }
    }
    return { preventDefault: false, shouldTriggerListeners: true };
  };

  const { preventDefault, shouldTriggerListeners } = actionsToTake();

  if (preventDefault) {
    e.preventDefault();
  }
  if (shouldTriggerListeners) {
    triggerListeners(code, e);
  }
});

/** @deprecated: Use useKey instead (new focus engine only) */
export const useKeyListener = (input: Input, ignoreFocus = false) => {
  const hasFocus = useContext(FocusContext);
  const isModal = useContext(ModalContext);

  useEffect(() => {
    if (hasFocus && isModal) {
      modalsActive++;
      return () => {
        modalsActive--;
      };
    }
  }, [hasFocus, isModal]);

  const skip = !hasFocus && !ignoreFocus;

  useEffect(() => {
    if (skip) return;
    const listeners = isModal ? modalListeners : nonModalListeners;
    Object.keys(input).forEach((key) => {
      const clickedKey = key as keyof Input;
      if (input[clickedKey]) {
        listeners[clickedKey]?.push(input[clickedKey] as Func);
      }
    });

    return () => {
      Object.keys(input).forEach((key) => {
        const clickedKey = key as keyof Input;
        listeners[clickedKey] = listeners[clickedKey]?.filter(
          (f) => f !== input[clickedKey]
        );
      });
    };
  }, [skip, input, isModal]);

  return null;
};

const isInput = (event: KeyboardEvent) => {
  const tagName = (event?.target as {
    tagName?: string;
  })?.tagName?.toLowerCase();
  return tagName === 'input';
};

export const useKey = (input: Input) => {
  useEffect(() => {
    const listeners = nonModalListeners;
    Object.keys(input).forEach((key) => {
      const clickedKey = key as keyof Input;
      if (input[clickedKey]) {
        listeners[clickedKey]?.push(input[clickedKey] as Func);
      }
    });

    return () => {
      Object.keys(input).forEach((key) => {
        const clickedKey = key as keyof Input;
        listeners[clickedKey] = listeners[clickedKey]?.filter(
          (f) => f !== input[clickedKey]
        );
      });
    };
  }, [input]);

  return null;
};
