import { useBookmarkActions } from '@sfstudios/shapeshifter';
import { useEffect } from 'react';

export const useSyncBookmarks = () => {
  const { syncBookmarks } = useBookmarkActions();

  // sync bookmarks when app starts
  useEffect(() => {
    syncBookmarks();
  }, [syncBookmarks]);
};
