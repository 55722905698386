import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';
import { FocusContext } from '../components/Focusable';
import { useKeyListener } from './useKeyListener';
import { useOutOfBoundsRef } from './useOutOfBounds';
import _throttle from 'lodash/throttle';
import { isLowEnd } from '../utils/platform';

const NAVIGATION_MIN_DELAY = 18;

export const useColumnNavigation = (
  numberOfColumns: number,
  active = true,
  initialFocus = 0
) => {
  const [focusedCol, setFocusedCol] = useState(initialFocus);
  const outOfBoundsRef = useOutOfBoundsRef();
  const hasFocus = useContext(FocusContext);

  const onLeft = useRef(
    _throttle(() => {
      requestAnimationFrame(() => {
        setFocusedCol((c) => c - 1);
      });
    }, NAVIGATION_MIN_DELAY)
  );

  const onRight = useRef(
    _throttle(() => {
      requestAnimationFrame(() => {
        setFocusedCol((c) => c + 1);
      });
    }, NAVIGATION_MIN_DELAY)
  );

  useEffect(() => {
    setFocusedCol((col) => Math.max(0, Math.min(col, numberOfColumns - 1)));
  }, [numberOfColumns]);

  useKeyListener(
    active
      ? {
          left: () => {
            if (focusedCol === 0) {
              outOfBoundsRef.current.left();
            } else {
              onLeft.current();
            }
          },
          right: () => {
            if (focusedCol + 1 >= numberOfColumns) {
              outOfBoundsRef.current.right();
            } else {
              onRight.current();
            }
          }
        }
      : {}
  );

  return hasFocus ? focusedCol : -1;
};

export const useRowNavigationKeyListener = ({
  active,
  focusedRow,
  setFocusedRow,
  numberOfRows
}: {
  active: boolean;
  focusedRow: number;
  numberOfRows: number;
  setFocusedRow: (rowIndex: number) => void;
}) => {
  const outOfBoundsRef = useOutOfBoundsRef();
  useKeyListener(
    active
      ? {
          up: () => {
            if (focusedRow === 0) {
              outOfBoundsRef.current.up();
            } else {
              setFocusedRow(focusedRow - 1);
            }
          },
          down: () => {
            if (focusedRow >= numberOfRows - 1) {
              outOfBoundsRef.current.down();
            } else {
              setFocusedRow(focusedRow + 1);
            }
          }
        }
      : {}
  );
};

export const useRowNavigation = (
  numberOfRows: number,
  active = true,
  initialRowFocus = 0
) => {
  const [focusedRow, setFocusedRow] = useState<number>(initialRowFocus);

  useRowNavigationKeyListener({
    active,
    focusedRow,
    setFocusedRow,
    numberOfRows
  });

  return focusedRow;
};

type LocationGroupName = string;
type LocationName = string;
type LocationGroupCache = {
  [key: string]: string;
};
type LocationNavigationTarget = LocationName | (() => LocationName);
type LocationNavigationAction = 'up' | 'down' | 'left' | 'right';
export type LocationNavigationHandlers = {
  [key in LocationNavigationAction]?: LocationNavigationTarget;
};
type LocationDefinition = LocationNavigationHandlers & {
  group?: LocationGroupName;
};
export type LocationMap = {
  [key: string]: LocationDefinition;
};
type LocationNavigationHandlerDefaults = {
  [key in LocationNavigationAction]?: LocationNavigationTarget | (() => void);
};

export const useLocationNavigation = (
  locationMap: LocationMap,
  initialLocationName: string,
  defaultActions: LocationNavigationHandlerDefaults = {},
  active: boolean = true
) => {
  const groupCacheRef = useRef<LocationGroupCache>({});
  const [currentLocationName, setCurrentLocationName] = useState(
    initialLocationName
  );
  const hasFocus = useContext(FocusContext);

  useEffect(() => {
    groupCacheRef.current = Object.entries(locationMap).reduce(
      (
        acc: LocationGroupCache,
        [name, loc]: [LocationName, LocationDefinition]
      ) => {
        if (loc.group && !acc[loc.group])
          return {
            ...acc,
            [loc.group]: groupCacheRef.current[loc.group] || name
          };
        return acc;
      },
      {}
    );
  }, [locationMap]);

  const resolveValue = (value: any) =>
    typeof value !== 'function' ? value : value();

  const handleActionForLocation = (
    actionName: LocationNavigationAction
  ) => () => {
    const currentLocation = locationMap[currentLocationName] || null;

    const maybeHandler = currentLocation?.[actionName];
    const defaultHandler = defaultActions?.[actionName];

    if (!maybeHandler) {
      console.info(
        `No handler for action \`${actionName}\` in location ${currentLocationName}`,
        { currentLocation }
      );
    }

    const nextLocationOrGroupName =
      resolveValue(maybeHandler) || resolveValue(defaultHandler);

    if (!nextLocationOrGroupName) return;

    const nextLocationName = locationMap[nextLocationOrGroupName]
      ? nextLocationOrGroupName
      : groupCacheRef.current[nextLocationOrGroupName];

    if (typeof nextLocationName !== 'string') {
      console.warn(
        `No target location name received for action \`${actionName}\` in location ${currentLocationName}`,
        { currentLocation }
      );
      return;
    }

    const nextLocation = locationMap[nextLocationName];

    if (!nextLocation) {
      console.warn(
        `${currentLocationName}.${actionName}(): Target \`${nextLocationName}\` not found in location map`,
        { currentLocation, locationMap }
      );
      return;
    }

    setCurrentLocationName(nextLocationName);
  };

  useEffect(() => {
    const location = locationMap[currentLocationName];
    if (location?.group) {
      groupCacheRef.current[location.group] = currentLocationName;
    }
  }, [currentLocationName, locationMap]);

  useKeyListener(
    active
      ? {
          up: handleActionForLocation('up'),
          left: handleActionForLocation('left'),
          down: handleActionForLocation('down'),
          right: handleActionForLocation('right')
        }
      : {}
  );

  return (hasFocus && active && currentLocationName) || null;
};

export const useGridNavigation = (
  rows: number,
  columns: number,
  orphanRowLength: number
): [
  State & { focusedCol: number },
  React.CSSProperties,
  (event: React.TransitionEvent<HTMLElement>) => void,
  (y: number, i: number) => void
] => {
  const [focusedCol, setFocusedCol] = useState(0);
  const hasFocus = useContext(FocusContext);
  const outOfBounds = useOutOfBoundsRef();

  let actualRows =
    orphanRowLength > 0 && focusedCol > orphanRowLength - 1 ? rows - 1 : rows;
  const [
    state,
    wrapperStyles,
    onTransitionEnd,
    onRowFocused
  ] = useRowNavigationWithScroll(actualRows, true);

  let actualCols =
    state.focusedRow === rows - 1 && orphanRowLength > 0
      ? orphanRowLength
      : columns;

  useKeyListener({
    left: () => {
      if (focusedCol > 0) {
        setFocusedCol(focusedCol - 1);
      } else {
        outOfBounds.current.left();
      }
    },
    right: () => {
      if (focusedCol < actualCols - 1) {
        setFocusedCol(focusedCol + 1);
      } else {
        outOfBounds.current.right();
      }
    }
  });

  return [
    hasFocus
      ? { ...state, focusedCol }
      : {
          ...state,
          focusedRow: -1,
          focusedCol: -1
        },
    wrapperStyles,
    onTransitionEnd,
    onRowFocused
  ];
};
interface State {
  focusedRow: number;
  scrollPos: number;
  scrolledToRow: number;
  outOfBoundsBottomTrigger: number;
  outOfBoundsTopTrigger: number;
}

type Action =
  | {
      type: 'KEY_UP';
    }
  | {
      type: 'KEY_DOWN';
    }
  | { type: 'TRANSITION_END' }
  | { type: 'ROW_FOCUSED'; payload: { y: number; i: number } };

const initialState: State = {
  focusedRow: 0,
  outOfBoundsTopTrigger: 0,
  outOfBoundsBottomTrigger: 0,
  scrollPos: 0,
  scrolledToRow: 0
};

const reducer = (numberOfRows: number) => (
  state: State,
  action: Action
): State => {
  switch (action.type) {
    case 'KEY_DOWN': {
      const newRow = Math.min(numberOfRows - 1, state.focusedRow + 1);

      return {
        ...state,
        outOfBoundsBottomTrigger:
          newRow === state.focusedRow
            ? state.outOfBoundsBottomTrigger + 1
            : state.outOfBoundsBottomTrigger,
        scrolledToRow:
          newRow !== state.focusedRow
            ? state.scrolledToRow
            : state.scrolledToRow,
        focusedRow: newRow
      };
    }
    case 'KEY_UP': {
      const newRow = Math.max(0, state.focusedRow - 1);
      return {
        ...state,
        outOfBoundsTopTrigger:
          newRow === state.focusedRow
            ? state.outOfBoundsTopTrigger + 1
            : state.outOfBoundsTopTrigger,
        scrolledToRow:
          newRow !== state.focusedRow
            ? state.scrolledToRow
            : state.scrolledToRow,
        focusedRow: newRow
      };
    }
    case 'TRANSITION_END': {
      return {
        ...state,
        scrolledToRow: state.focusedRow
      };
    }
    case 'ROW_FOCUSED': {
      const y = action.payload.y;

      if (action.payload.i !== state.focusedRow) {
        return state;
      }
      if (y === state.scrollPos) {
        return {
          ...state,
          scrolledToRow: state.focusedRow
        };
      }
      return {
        ...state,
        scrollPos: y
      };
    }
  }
};

export type OnRowFocused = (y: number, i: number) => void;

export const useRowNavigationWithScroll = (
  numberOfRows: number,
  active: boolean = true
): [
  State,
  React.CSSProperties,
  (event: React.TransitionEvent<HTMLElement>) => void,
  OnRowFocused,
  {
    onUp: React.MutableRefObject<() => void>;
    onDown: React.MutableRefObject<() => void>;
  }
] => {
  const hasFocus = useContext(FocusContext);
  const outOfBoundsRef = useOutOfBoundsRef();
  const [state, dispatch] = useReducer(
    // eslint-disable-next-line
    useCallback(reducer(numberOfRows), [numberOfRows]),
    initialState
  );
  useEffect(() => {
    if (state.outOfBoundsTopTrigger > 0) {
      outOfBoundsRef.current.up();
    }
  }, [outOfBoundsRef, state.outOfBoundsTopTrigger]);

  useEffect(() => {
    if (state.outOfBoundsBottomTrigger > 0) {
      outOfBoundsRef.current.down();
    }
  }, [outOfBoundsRef, state.outOfBoundsBottomTrigger]);

  const onUp = useRef(
    _throttle(() => {
      requestAnimationFrame(() => {
        dispatch({ type: 'KEY_UP' });
      });
    }, NAVIGATION_MIN_DELAY)
  );

  const onDown = useRef(
    _throttle(() => {
      requestAnimationFrame(() => {
        dispatch({ type: 'KEY_DOWN' });
      });
    }, NAVIGATION_MIN_DELAY)
  );

  useKeyListener(
    active
      ? {
          up: onUp.current,
          down: onDown.current
        }
      : {}
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const onRowFocused = useCallback(
    (y: number, i: number) => {
      if (i === 0) {
        y = 0;
      }
      dispatch({ type: 'ROW_FOCUSED', payload: { y, i } });
    },
    [dispatch]
  );

  const transitionEnd = useCallback((e) => {
    if (e.target === e.currentTarget) {
      dispatch({ type: 'TRANSITION_END' });
    }
  }, []);

  /**
   * We disable transitions on low end devices, so we need to manually trigger the
   * transition end event on scrolling on low end devices.
   */
  useEffect(() => {
    if (isLowEnd) {
      dispatch({ type: 'TRANSITION_END' });
    }
  }, [state.scrollPos]);

  return [
    hasFocus ? state : { ...state, focusedRow: -1 },
    {
      transform: `translateZ(0) translateY(-${state.scrollPos}px)`,
      transition: isLowEnd ? undefined : `transform 225ms ease`,
      willChange: isLowEnd ? undefined : `transform`
    },
    transitionEnd,
    onRowFocused,
    { onUp, onDown }
  ];
};
