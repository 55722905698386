import { useCallback, useEffect } from 'react';
import { Message_VisibilityChanged } from '../contract/CommunicationContract';
import { Platform } from '../utils/platform';
import { useListenToMessageFromParent } from './useListenToMessageFromParent';

export const useOnVisibilityStatusChanged = (
  targetState: 'visible' | 'hidden',
  callback: () => void
) => {
  const onVisibilityChanged = useCallback(
    ({ visible }: Message_VisibilityChanged) => {
      if (targetState === 'visible' && visible) callback();
      if (targetState === 'hidden' && !visible) callback();
    },
    [callback, targetState]
  );

  useListenToMessageFromParent({
    action: 'VISIBILITY_CHANGE',
    callback: onVisibilityChanged
  });

  useEffect(() => {
    const visibilityChangeListener = () => {
      // Samsung is handled above instead, since the actual show/hide events
      // come from the TV-package, routed through our iframe communications
      // layer.
      if (Platform.samsung()) return;

      if (document.visibilityState === targetState) {
        callback();
      }
    };

    document.addEventListener('visibilitychange', visibilityChangeListener);
    return () => {
      document.removeEventListener(
        'visibilitychange',
        visibilityChangeListener
      );
    };
  }, [callback, targetState]);
};

export const useOnBecomeVisible = (callback: () => void) =>
  useOnVisibilityStatusChanged('visible', callback);
