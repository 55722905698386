import React, { createContext, useContext, useState, useCallback } from 'react';
import { history } from '../utils/historySingleton';
import { useListenToMessageFromParent } from '../hooks/useListenToMessageFromParent';
import { urlFromItem } from '../utils/urlFromItem';
import { Message_DeepLink } from '../contract/CommunicationContract';

export const DeepLinkContext = createContext(false);

export const useStartedFromDeepLink = () => useContext(DeepLinkContext);

export const DeepLinkContextProvider: React.FC<{setPlaySlug: (slug: string) => void}> = ({ children, setPlaySlug }) => {
  /**
   * This tracks in context whether the app has been triggered by a deep link or not.
   * Samsung requires some changes in behavior when deeplinks are triggered.
   */
  const [startedFromDeepLink, setStartedFromDeepLink] = useState(false);

  const onDeepLink = useCallback(
    (deepLink: Message_DeepLink) => {
      const url = urlFromItem({
        __typename: deepLink.type,
        slug: deepLink.slug,
      });

      // This allow us to forward tracking parameters etc from our deep links.
      const queryParams = typeof deepLink.extra?.queryParams ===  "string" ? `?${deepLink.extra.queryParams}` : ""

      if (url) {
        history.push(`${url}${queryParams}`);

        // Ensure we exit the player whenever we push a new deep link
        setPlaySlug("")
      }

      if (!startedFromDeepLink) {
        setStartedFromDeepLink(true);
      }
    },
    [setPlaySlug, startedFromDeepLink]
  );

  useListenToMessageFromParent({
    action: 'DEEP_LINK',
    callback: onDeepLink,
  });

  return (
    <DeepLinkContext.Provider value={startedFromDeepLink}>
      {children}
    </DeepLinkContext.Provider>
  );
};
