import { ButtonProps, darkTheme } from '@sfstudios/shapeshifter';
import Icon, { IconName } from '../../Icon';
import { whiteSpace } from '../../../theme';
import * as MoreStyled from './More.styled';

interface Props extends ButtonProps {
  onClick?: () => void;
  icon?: IconName;
}

export const Button = (props: Props) => {
  const { children, icon, onClick } = props;

  return (
    <MoreStyled.ButtonWrapper onClick={onClick}>
      <MoreStyled.ButtonOutline>
        <MoreStyled.ButtonBody>
          {icon && (
            <Icon
              style={{ marginRight: whiteSpace['6'] }}
              size={32}
              className="base-icon"
              fill={darkTheme.color.ui100}
              name={icon}
            />
          )}
          <MoreStyled.ButtonLabel>{children}</MoreStyled.ButtonLabel>
          <MoreStyled.IconLeft />
        </MoreStyled.ButtonBody>
      </MoreStyled.ButtonOutline>
    </MoreStyled.ButtonWrapper>
  );
};
