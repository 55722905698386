import {
  ButtonProps,
  useL10n,
  useServiceAvailability
} from '@sfstudios/shapeshifter';
import styled from 'styled-components';
import { whiteSpace } from '../../../theme';
import { OutlinedButton, UnstyledHTMLButton } from '../../Button';
import { Focusable } from '../../Focusable';
import Typography from '../../Typography';
import {
  BodySection,
  CheckoutModalContainer,
  HeaderSection
} from '../CheckoutModal/CheckoutModal.styled';
import { Modal } from '../Modal';

type Message = ReturnType<
  typeof useServiceAvailability
>['messagesToShow'][number];

export const ServiceAvailabilityModal = ({
  message,
  dismiss
}: {
  message: Message | undefined;
  dismiss: (m: Message) => void;
}) => {
  const { t } = useL10n();
  if (!message) return null;
  const { title, text } = message;
  return (
    <Modal animate visible={!!message}>
      <div style={{ height: '50%' }} />
      <CheckoutModalContainer>
        <HeaderSection>
          <Typography variant="superBold">{title}</Typography>
        </HeaderSection>
        <Body>
          <Typography variant="largeTitleRegular">{text}</Typography>
          <div style={{ height: whiteSpace[16] }} />
          <Focusable<ButtonProps>
            as={UnstyledHTMLButton}
            focused={true}
            onClick={() => dismiss(message)}
          >
            <OutlinedButton>{t('glossary.close_modal')}</OutlinedButton>
          </Focusable>
        </Body>
      </CheckoutModalContainer>
    </Modal>
  );
};

const Body = styled(BodySection)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${whiteSpace[16]}px;
`;
