import { themeColor, useL10n, usePrevious } from '@sfstudios/shapeshifter';
import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconButton } from '../../components/pages/Search/Button';
import Typography from '../../components/Typography';
import { FocusGroup } from '../../focus-engine/FocusGroup';
import { useKey } from '../../hooks/useKeyListener';
import { vh100, vw100 } from '../styleUtils';

interface Props {
  children: ReactNode;
  visible: boolean;
  titleKey: string;
  onClose: () => void;
}

const animationDuration = 250;

export const Drawer = ({ children, onClose, visible, titleKey }: Props) => {
  const [animating, setAnimating] = useState(visible);
  const { t } = useL10n();
  const wasVisible = usePrevious(visible);
  const animationWillStart = wasVisible !== undefined && wasVisible !== visible;

  useEffect(() => {
    if (!animationWillStart) return;
    setAnimating(true);
    const t = setTimeout(() => setAnimating(false), animationDuration);
    return () => clearTimeout(t);
  }, [animationWillStart]);

  useKey(
    visible
      ? {
          back: onClose,
          left: onClose
        }
      : {}
  );

  if (!animating && !visible) return null;

  return (
    <Container>
      <Overlay visible={visible} />
      <Wrapper
        visible={visible}
        id="drawer"
        blockMovingRight
        blockMovingUp
        blockMovingDown
        blockMovingLeft
      >
        <ButtonWrapper>
          <IconButton icon="close" outlined onClick={onClose} />
        </ButtonWrapper>
        <Content>
          <Title variant="superBold">{t(titleKey)}</Title>
          {children}
        </Content>
      </Wrapper>
    </Container>
  );
};

const Title = styled(Typography)`
  color: ${themeColor('ui200')};
  margin-bottom: 32px;
`;

const Overlay = styled.div<{ visible: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: ${themeColor('ui900', 0.5)};

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  animation: ${(props) =>
    `${props.visible ? 'fadeIn' : 'fadeOut'} ${animationDuration}ms`};
  animation-fill-mode: forwards;
`;

const Container = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  height: ${vh100};
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: ${vw100};
  overflow: hidden;
`;

const slideDistance = `700px`;

const Wrapper = styled(FocusGroup)<{ visible: boolean }>`
  transform: translateX(${slideDistance});

  @keyframes slideIn {
    0% {
      transform: translateX(${slideDistance});
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(${slideDistance});
    }
  }

  animation: ${(props) =>
    `${props.visible ? 'slideIn' : 'slideOut'} ${animationDuration}ms`};

  animation-fill-mode: forwards;
  position: relative;
  right: 0;

  height: 100%;
  display: flex;
  align-items: flex-start;
`;

const topPadding = 75;

const Content = styled.div`
  width: 640px;
  height: 100%;
  background-color: ${themeColor('ui700')};
  box-shadow: 0px 4px 4px #0d0d0d, 0px 4px 16px #0d0d0d;
  padding: 32px;
  padding-top: ${topPadding}px;
`;

const ButtonWrapper = styled.div`
  padding-top: ${topPadding}px;
  padding-right: 16px;
`;
