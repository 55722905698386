import { useEffect, useRef } from 'react';

export const useMouse = () => {
  const isCursorVisible = useRef(false);

  useEffect(() => {
    const onKeyDown = () => (isCursorVisible.current = false);
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  });

  useEffect(() => {
    const cursorMoved = () => (isCursorVisible.current = true);
    document.addEventListener('mousemove', cursorMoved);
    return () => {
      document.removeEventListener('mousemove', cursorMoved);
    };
  }, [isCursorVisible]);

  useEffect(() => {
    function lgCursorVisibilityChange(event: any) {
      const visibility = event.detail.visibility;
      isCursorVisible.current = !!visibility;
    }
    document.addEventListener(
      'cursorStateChange',
      lgCursorVisibilityChange,
      false
    );
    return () => {
      document.removeEventListener(
        'cursorStateChange',
        lgCursorVisibilityChange,
        false
      );
    };
  }, []);

  const isKeyboardVisible = useRef(false);
  useEffect(() => {
    const listener = (event: any) => {
      isKeyboardVisible.current = !!event?.detail?.visibility;
    };
    document.addEventListener('keyboardStateChange', listener);
    return () => document.removeEventListener('keyboardStateChange', listener);
  }, []);

  return {
    isCursorVisible: isCursorVisible as React.RefObject<boolean>,
    isKeyboardVisible: isKeyboardVisible as React.RefObject<boolean>
  };
};
