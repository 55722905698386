import { Colors, darkTheme } from '@sfstudios/shapeshifter';
import React, { forwardRef, useContext, useMemo } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { fontSize, whiteSpace } from '../theme';
import { ButtonProps } from '../types';
import { isLowResolution } from '../utils/deviceInfo';
import { FocusContext } from './Focusable';
import Icon, { IconName } from './Icon';

export const UnstyledHTMLButton = forwardRef<HTMLButtonElement>(
  (props, ref) => <button {...props} className="unstyled-button" ref={ref} />
);

type ButtonStyleProps = {
  fillColor?: string;
  outlineColor?: string;
  innerOutlineColor?: string;
  textColorName?: keyof Colors;
  width?: number | string;
  iconEnd?: IconName;
  iconStart?: IconName;
  bodyPadding?: string;
};

const ButtonOutline = styled.div<{ textOnly?: boolean }>`
  border: 4px solid transparent;
  padding: ${whiteSpace['1']};
  border-radius: 16em;
  ${({ textOnly }) => (textOnly ? '' : 'width: 400px')};

  .button-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: ${isLowResolution() ? '3rem' : '5rem'};
    border-radius: 16em;
    border: solid 2px transparent;
    padding: ${whiteSpace['4']} ${whiteSpace['12']};

    .button-label {
      font-size: ${fontSize.base};
      font-weight: 600;
    }

    .base-icon {
      width: 3em;
      height: 3em;
      position: relative;
      margin-right: 0.5em;
    }
  }
`;

type BaseButtonProps = {
  children?: React.ReactNode;
  textOnly?: boolean;
  outlineColorName?: 'ui100';
} & ButtonStyleProps;

export const BaseButton = React.memo(
  ({
    width,
    iconEnd,
    iconStart,
    children,
    fillColor = darkTheme.color.cta,
    outlineColor,
    outlineColorName,
    textColorName = 'ui900',
    bodyPadding,
    textOnly,
    ...rest
  }: BaseButtonProps) => {
    const theme = useTheme();
    const textColor = theme.color[textColorName];
    const innerOutlineColor = outlineColorName
      ? theme.color[outlineColorName]
      : rest.innerOutlineColor ?? 'transparent';
    return (
      <ButtonOutline
        textOnly={textOnly}
        style={useMemo(() => ({ width, borderColor: outlineColor }), [
          outlineColor,
          width
        ])}
      >
        <div
          className="button-body"
          style={useMemo(
            () => ({
              borderColor: innerOutlineColor,
              backgroundColor: fillColor,
              padding: bodyPadding
            }),
            [bodyPadding, fillColor, innerOutlineColor]
          )}
        >
          {iconStart && (
            <Icon className="base-icon" fill={textColor} name={iconStart} />
          )}
          <div
            className="button-label"
            style={useMemo(() => ({ color: textColor }), [textColor])}
          >
            {children}
          </div>
          {iconEnd && (
            <Icon className="base-icon" fill={textColor} name={iconEnd} />
          )}
        </div>
      </ButtonOutline>
    );
  }
);

type FocusableBaseButtonProps = ButtonProps &
  BaseButtonProps & { focused?: boolean };

/**
 * @deprecated Use other Button component.
 * ❗️Page must use new focus engine first❗️
 **/
export const PrimaryButton = (props: FocusableBaseButtonProps) => {
  const regularFocus = useContext(FocusContext);
  let { focused = false } = props;
  return (
    <BaseButton
      outlineColor={
        focused || regularFocus ? darkTheme.color.ui100 : 'transparent'
      }
      {...props}
    />
  );
};

/**
 * @deprecated Use other Button component.
 * ❗️Page must use new focus engine first❗️
 **/
export const OutlinedButton = (props: FocusableBaseButtonProps) => {
  const regularFocus = useContext(FocusContext);
  let { focused = false } = props;
  focused = focused || regularFocus;
  return (
    <BaseButton
      fillColor={focused ? darkTheme.color.ui100 : 'transparent'}
      textColorName={focused ? 'ui900' : 'ui100'}
      outlineColor={focused ? darkTheme.color.ui100 : 'transparent'}
      innerOutlineColor={darkTheme.color.ui100}
      {...props}
    />
  );
};

/**
 * @deprecated Use other Button component.
 * ❗️Page must use new focus engine first❗️
 **/
export const MinimalButton = (props: FocusableBaseButtonProps) => {
  const regularFocus = useContext(FocusContext);
  let { focused = false } = props;
  focused = focused || regularFocus;
  return (
    <BaseButton
      fillColor={focused ? darkTheme.color.ui100 : 'transparent'}
      textColorName={focused ? 'ui900' : 'ui100'}
      outlineColor={focused ? darkTheme.color.ui100 : 'transparent'}
      {...props}
    />
  );
};

export const SeasonButton = (props: FocusableBaseButtonProps) => {
  const regularFocus = useContext(FocusContext);
  let { focused = false } = props;
  focused = focused || regularFocus;
  return (
    <BaseButton
      fillColor={focused ? darkTheme.color.ui100 : 'transparent'}
      textColorName={focused ? 'ui900' : 'ui100'}
      outlineColor={focused ? darkTheme.color.ui100 : 'transparent'}
      innerOutlineColor={darkTheme.color.ui100}
      width={'fit-content'}
      bodyPadding={`${whiteSpace['4']} ${whiteSpace['8']}`}
      {...props}
    />
  );
};
