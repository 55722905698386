export const getPhilipsVersion = (ua?: string): number | null => {
  ua = ua ?? navigator.userAgent;
  const regex = /TV_([A-Z0-9]+_([\d]{4}))/;
  const match = ua.match(regex);

  if (match) {
    const [, , year] = match;
    return parseInt(year);
  }

  if (ua.includes('_TV_MT5800')) return 2020;
  if (ua.includes('_TV_MT5806')) return 2020;

  return null;
};
