import { El, Shape, RectPosition } from './types';

export const withRect = (element: El) => ({
  element,
  rect: (() => {
    const rect = (element.node as any).getBoundingClientRect() as DOMRect;

    // Some old browsers don't support x and y and have left and top instead
    const needsGetBoundingClientRectFix = rect.x == null || rect.y == null;
    if (needsGetBoundingClientRectFix) {
      rect.x = rect.left;
      rect.y = rect.top;
    }

    const { width, height, x, y } = rect;

    const topCenter = { x: x + width / 2, y };
    const bottomCenter = {
      x: x + width / 2,
      y: y + height
    };
    const leftCenter = { x, y: y + height / 2 };
    const rightCenter = { x: x + width, y: y + height / 2 };
    const topLeft = { x, y };
    const topRight = { x: x + width, y };
    const bottomLeft = { x, y: y + height };
    const bottomRight = {
      x: x + width,
      y: y + height
    };
    const center = {
      x: x + width / 2,
      y: y + height / 2
    };
    const measures: Shape = {
      topCenter,
      bottomCenter,
      leftCenter,
      rightCenter,
      topLeft,
      topRight,
      bottomLeft,
      bottomRight,
      center
    };
    return Object.entries(measures).reduce(
      (acc, [key, value]) => {
        acc[key as RectPosition] = {
          x: Math.round(value.x),
          y: Math.round(value.y)
        };
        return acc;
      },
      { width, height } as Shape & {
        width: number;
        height: number;
      }
    );
  })()
});
