import _throttle from 'lodash/throttle';
import { useEffect } from 'react';
import { handleDownArrow, handleUpArrow } from '../utils/eventlisteners';
import { Platform } from '../utils/platform';

const useScroll = () => {
  useEffect(() => {
    if (!Platform.supportsScroll()) return;

    const listener = _throttle((event: { deltaY: number }) => {
      if (event.deltaY > 0) {
        handleDownArrow();
      } else {
        handleUpArrow();
      }
    }, 1500);

    window.addEventListener('wheel', listener);
    return () => {
      window.removeEventListener('wheel', listener);
    };
  });
};

export { useScroll };
