import { useMenu } from '@sfstudios/shapeshifter';
import queryString from 'query-string';
import { CardRoutes, PageRoutes } from '../types';
import { history } from '../utils/historySingleton';

export type QueryParams = {
  [x: string]: string | string[] | undefined;
};

export type NavigationItem = ReturnType<typeof useMenu>['items'][number];

export const getPathname = (route: string, params?: QueryParams) => {
  let pathname = route;
  if (params) {
    Object.entries(params).forEach(([param, value]) => {
      if (value === undefined) return;
      pathname = pathname.replace(
        `:${param}`,
        Array.isArray(value) ? value[0] : value
      );
    });
  }
  return pathname;
};

const resolveLocalPath = (id: NavigationItem['id']): string => {
  const routes: Partial<Record<NavigationItem['id'], PageRoutes>> = {
    settings: PageRoutes.MyAccount,
    more: PageRoutes.More,
    search: PageRoutes.Search
  };
  const routeId = routes[id];
  if (!routeId) throw new Error(`No local path found for ${id}`);
  const route = getPathname(routeId, { slug: id });
  if (!route) throw new Error(`No local path found for ${id}`);
  return route;
};

export const resolvePath = ({ item }: { item: NavigationItem }): string => {
  const type = item.type;
  switch (type) {
    case 'list':
      return getPathname(PageRoutes.List, item.params);
    case 'contentCollection':
      return getPathname(PageRoutes.ContentCollection, item.params);
    case 'custom':
      return resolveLocalPath(item.id);
    default:
      throw new Error(`Cannot resolve path for item type ${type}`);
  }
};

export const getSearch = (queryParams?: QueryParams) => {
  let parsed = (queryParams && queryString.stringify(queryParams)) || '';
  return parsed ? `?${parsed}` : '';
};

export const navigateToCard = (
  targetCard: CardRoutes,
  params?: QueryParams,
  queryParams?: QueryParams
) => {
  const pathname = getPathname(targetCard, params);
  const search = getSearch(queryParams);

  const onCardRoute = Boolean(history.location.state?.page);

  const pageBehindCard = onCardRoute
    ? history.location.state.page
    : history.location;

  history.push({
    pathname,
    state: {
      page: pageBehindCard
    },
    search
  });
};

export const navigateToPage = ({
  targetPage,
  params,
  queryParams
}: {
  targetPage: PageRoutes;
  params?: QueryParams;
  queryParams?: QueryParams;
}) => {
  const pathname = getPathname(targetPage, params);
  const search = getSearch(queryParams);

  history.push({
    pathname,
    search
  });
};
