import { useEffect, useCallback } from 'react';
import { Message_KeyMapping } from '../../contract/CommunicationContract';
import { setCustomKeyMapping } from '../useKeyListener';

import { getKeyMap } from './utils';

export const useKeyMapping = () => {
  /**
   * Set custom key mapping if platform reports it. We do this weird mapping
   * because we changed the API in the hosted app, but the Samsung app reports
   * this via the TV-package, which we can't control the updates for.
   */
  const onKeyMappingMessage = useCallback(({ keys }: Message_KeyMapping) => {
    setCustomKeyMapping(
      Object.entries(keys).reduce(
        (acc: { [keyName: string]: number[] }, [keyName, key]) => {
          acc[keyName] = Array.isArray(key) ? key : [key];
          return acc;
        },
        {}
      )
    );
  }, []);

  useEffect(() => {
    const map = getKeyMap();
    if (!map) return;

    setCustomKeyMapping(map);
  }, []);

  return { onKeyMappingMessage };
};
