import * as Sentry from '@sentry/browser';
import { useApp } from '@sfstudios/shapeshifter';
import { useEffect } from 'react';
import { getEnvVariable } from '../utils/getEnvVariable';

export const useSentry = () => {
  const { configs } = useApp();
  const dsn = configs.SENTRY_DSN;

  useEffect(() => {
    if (!dsn) return;
    Sentry.init({
      dsn,
      release: getEnvVariable('REACT_APP_HEROKU_RELEASE_VERSION', 'unknown')
    });
  }, [dsn]);
};
